import {Button, Col, DatePicker, Row, Select} from 'antd'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import {
    departmentObj,
    filterOption,
} from '../../../components/_utils/appUtils'
import {getUrlPushWrapper} from '../../../routes'
import {listAllUsers} from '../../users/actions/user'

import {getCountry} from '../../countries/actions/countries'
import {
    directUniversityRecordsCountFxn,
    getMeetingsCount,
    marketingManagerReportsFxn
} from '../../reporting/actions'
import MeetingsCountComponent from '../../reporting/views/meetingsCountComponent'
import ReportingComponent from './reportingComponent'

const {RangePicker} = DatePicker

const {Option} = Select
const MarketingDashboardComponent = props => {
    let {currentUser} = props
    let [countryId, setCountryId] = useState('')
    let [countryName, setCountryName] = useState('')
    const [selectUser, setSelectUser] = useState('')
    const [userId, setUserId] = useState('')
    const [userList, setUserList] = useState([])
    const [userData, setUserData] = useState({})
    const [department, setDepartment] = useState('')
    const [managerType, setManagerType] = useState('')
    const [directUniversityCount, setDirectUniversityCount] = useState({})
    const [toDate, setToDate] = useState(
        moment()
            .endOf('month')
            .startOf('day')
    )
    const [fromDate, setFromDate] = useState(
        moment()
            .subtract(3, 'month')
            .startOf('month')
            .endOf('day')
    )

    const [meetingData, setMeetingData] = useState({
        pendingCount: 0,
        notAttendedCount: 0,
        attendedCount: 0,
        totalMeetings: 0
    })

    const dispatch = useDispatch()

    useEffect(() => {
        loadUserList()
        loadCountry()
        setCountryId(currentUser.countryId)
    }, [])

    const loadCountry = async () => {
        let {countryId} = currentUser
        let data = await dispatch(getCountry(countryId))
        if (data && data.countryName) {
            setCountryName(data.countryName)
        }
    }

    const loadUserList = async () => {
        let userType = 'branchUser'
        let department = departmentObj.marketing
        if (userType) {
            let params = {
                results: 1000,
                count: 1000,
                userType,
                department: department,
                sortField: 'name',
                sortOrder: 'ascend',
                select: ['name', 'userType', 'mobile', 'email']
            }
            let {data} = await dispatch(listAllUsers(params))
            if (data && data.length) {
                setUserList(data)
                getParamsForApplicationList()
            }
        } else {
            setUserList([])
        }
    }

    useEffect(() => {
        reloadData()
    }, [])

    let reloadData = async () => {
        let resp = await getParamsForApplicationList()
        if (resp && resp.userId) {
            setSelectUser(resp.userId ? resp.userId : '')
            loadUserReport(resp.userId)
        } else {
            loadUserReport(null)
        }
    }

    const loadUserReport = async (userId = '') => {
        let obj = {
            userId: userId ? userId : ''
        }
        if (managerType) {
            obj.managerType = managerType
        }
        if (department) {
            obj.department = department
        }
        if (userId) {
            getMeetings(userId)
        }
        dispatch(getUrlPushWrapper('oldDashboard', obj))

        let params = {
            userId: userId ? userId : ''
        }
        if (fromDate) {
            params.fromDate = fromDate
            params.toDate = toDate
        }
        let data = await dispatch(marketingManagerReportsFxn({...params}))
        let directUniversityCountData = await dispatch(directUniversityRecordsCountFxn(params))
        setDirectUniversityCount(directUniversityCountData)
        setUserData(data)
    }
    const getMeetings = async userId => {
        let obj1 = {
            userId: userId
        }
        if (fromDate) {
            obj1.fromDate = new Date(fromDate)
            obj1.toDate = new Date(toDate)
        }
        let data = await dispatch(getMeetingsCount(obj1))
        let total = 0
        let obj = {}
        if (data) {
            data.map(item => {
                if (item._id == 'Pending') {
                    obj.pendingCount = item.count
                    total += item.count
                }
                if (item._id == 'notAttended') {
                    obj.notAttendedCount = item.count
                    total += item.count
                }
                if (item._id == 'attended') {
                    obj.attendedCount = item.count
                    total += item.count
                }
            })
        }
        setMeetingData({...obj, totalMeetings: total})
    }

    const getParamsForApplicationList = () => {
        return new Promise(resolve => {
            let searchParams = new URLSearchParams(window.location.search)

            let userId = searchParams.get('userId')
            let toDate = searchParams.get('toDate')
            let fromDate = searchParams.get('fromDate')
            let userType = searchParams.get('userType')
            let obj = {}
            if (userId) {
                setSelectUser(userId ? userId : '')
                obj.userId = userId
            }
            if (toDate) {
                obj.toDate = toDate
            }
            if (fromDate) {
                obj.fromDate = fromDate
            }
            if (userType) {
                obj.userType = userType
            }
            resolve(obj)
        })
    }

    return (
        <div className="row  mt-4">

            <div className="col-lg-12">
                <div className="card">
                    <div className="table-head d-flex align-items-center">
                        <h5>Reporting List</h5>
                    </div>
                    <Row style={{paddingLeft: 10, paddingTop: 10}}>
                        <Col md={6} sm={6} xs={6} lg={6} offset={1}>
                            <RangePicker
                                defaultValue={[fromDate, toDate]}
                                onChange={val => {
                                    setFromDate(val[0])
                                    setToDate(val[1])
                                }}
                            />
                        </Col>

                        <Col md={6} sm={6} xs={8} lg={6} offset={1}>
                            <div className="sort-box-table custom-sort-box-new">
                                <Select
                                    name="Select User"
                                    filterOption={filterOption}
                                    className={'antSelect'}
                                    allowClear={true}
                                    placeholder={'Select User'}
                                    showSearch={true}
                                    onChange={item => setSelectUser(item)}
                                    value={selectUser || undefined}>
                                    {userList && userList.length
                                        ? userList.map((item, key) => {
                                            let newName = `${item.name} (${item.email})`
                                            return (
                                                <Option value={item._id} key={key}>
                                                    {newName}
                                                </Option>
                                            )
                                        })
                                        : null}
                                </Select>
                            </div>
                        </Col>

                        <Col span={6} offset={1}>
                            <Button
                                onClick={() => loadUserReport(selectUser)}
                                className={'roundBtn'}>
                                Search
                            </Button>
                        </Col>
                    </Row>

                    {currentUser.userType && (
                        <ReportingComponent
                            userData={userData}
                            userType={currentUser.userType}
                            userId={selectUser}
                            department={department}
                            directUniversityCount={directUniversityCount}
                            toDate={toDate ? moment(toDate) : null}
                            fromDate={fromDate ? moment(fromDate) : null}
                            userCountryName={countryName}
                        />
                    )}

                    {meetingData && meetingData.totalMeetings ? (
                        <MeetingsCountComponent
                            userId={selectUser}
                            toDate={toDate ? moment(toDate) : null}
                            fromDate={fromDate ? moment(fromDate) : null}
                            {...props}
                            meetingData={meetingData}
                        />
                    ) : null}
                </div>
            </div>

            {/*{directUniversityData && !_.isEmpty(directUniversityData) ? <div className={'col-lg-12'}>
                {universityListForMarketing.map((item) => {
                        return (
                            <div className={'card'} key={item.id}>
                                <div className='table-head d-flex align-items-center'>
                                    <h5>{item.name}</h5>
                                </div>
                                <DirectUniversityCountComponent
                                    universityId={item.id}
                                    userType={currentUser.userType}
                                    userId={selectUser}
                                    department={department}
                                    toDate={moment(toDate)}
                                    fromDate={moment(fromDate)}
                                    universityData={directUniversityData[item.id]}/>
                            </div>
                        )
                    }
                )}
            </div> : null}*/}
        </div>
    )
}

const mapStateToProps = ({global}) => ({
    currentUser: global.currentUser,
    activeUser: global.activeUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketingDashboardComponent)
