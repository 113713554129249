import React, { useEffect, useState } from 'react'
import { Form, Button, notification, Drawer, Input, Col, Row } from 'antd'
import { useDispatch } from 'react-redux'
import { contractWith, countryIds, InputBox } from '../../../components/_utils/appUtils'
import { listAllCountries } from '../../countries/actions/countries'
import { listQuickSearchUniversities } from '../../university/actions/university'
import { addContract } from '../actions/contract'
import moment from 'moment'
import GetEachFormFields from '../../../components/_utils/appFormUtils'

const AddContract = (props) => {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue }, visible, events, universityTypeList } = props
  const dispatch = useDispatch()
  let [allCountry, setAllCountry] = useState([])
  let [countryUniversity, setCountryUniversity] = useState([])
  let [contract, setContract] = useState({})
  let [mediaKey, setMediaKey] = useState(moment())

  const chooseFiles = (files) => {
    setContract(files)
  }

  const appEvents = {
    loadCountry: async () => {
      let filters = {
        sortField: 'countryName', sortOrder: 'ascend',
        results: 100,
        count: 100
      }
      let { data } = await dispatch(listAllCountries(filters))
      setAllCountry(data)
    },
    loadUniversity: async (item) => {
      let obj = {
        results: 1000000,
        universityCountry: item,
        sortField: 'universityName',
        sortOrder: 'ascend'
      }
      let data = await dispatch(listQuickSearchUniversities(obj))
      setCountryUniversity(data)
    }
  }

  useEffect(() => {
    appEvents.loadCountry()
  }, [])


  let inputTypes = {
    fields: [
      {
        key: 'contractWith',
        label: 'Contract With',
        required: true,
        type: 'select',
        showSearch: true,
        options: contractWith,
        keyAccessor: x => x.name,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            contractWith: x
          })
        }
      },
      {
        key: 'countryId',
        label: 'Country',
        required: true,
        type: 'select',
        showSearch: true,
        options: allCountry,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.countryName}`,
        onChange: x => {
          setFieldsValue({
            countryId: x,
            universityId: undefined
          })
          appEvents.loadUniversity(x)
        }
      },
      {
        key: 'universityType',
        label: 'University Type',
        required: true,
        type: 'select',
        showSearch: true,
        options: universityTypeList,
        keyAccessor: x => x.value,
        valueAccessor: x => `${x.name}`,
        onChange: x => {
          setFieldsValue({
            universityType: x,
            universityId: undefined,
            universityName: undefined
          })
        }
      },
      {
        key: 'universityId',
        label: 'University',
        required: true,
        type: 'select',
        hidden: !(getFieldValue('universityType') && getFieldValue('universityType') == 'alreadyAdded'),
        showSearch: true,
        options: countryUniversity,
        keyAccessor: x => x._id,
        valueAccessor: x => `${x.universityName}`,
        onChange: x => {
          setFieldsValue({
            universityId: x
          })
        }
      },
      {
        key: 'universityName',
        label: 'University',
        required: true,
        placeholder: 'University',
        hidden: !(getFieldValue('universityType') && getFieldValue('universityType') == 'other')
      },
      {
        key: 'startDate',
        label: 'Choose StartDate',
        type: 'date',
        required: true
      },
      {
        key: 'endDate',
        label: 'Choose End Date',
        type: 'date',
        required: true
      },
      {
        label: 'contract',
        customField: (
          <InputBox title={'* Contract'} className={'rowFlex'} customStyle={{ marginTop: 10 }}>
            <Input type={'file'} name={'contract'} id={'contract'} key={mediaKey}
                   className={'form-control'}
                   onChange={(e) => {
                     chooseFiles(e.target.files && e.target.files.length ? e.target.files[0] : null)
                   }} />
          </InputBox>
        )
      },
      {
        key: 'details',
        label: 'Details',
        type: 'textarea',
        span: 24
      }
    ]
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 0 },
      sm: { span: 0 },
      md: { span: 0 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 24 }
    }
  }

  const handleSubmit = e => {
    const { form } = props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let obj = {
          contractWith: valData.contractWith,
          countryId: valData.countryId,
          universityId: valData.universityId,
          startDate: valData.startDate,
          endDate: valData.endDate,
          details: valData.details,
          universityType: valData.universityType,
          universityName: valData.universityName
        }
        let fd = new FormData()
        fd.append('obj', JSON.stringify(obj))
        if (!contract || (contract && !contract.name)) {
          notification.warning({ message: 'Please Choose contract.' })
          return
        }
        if (contract) {
          fd.append('contract', contract, contract.name)
        }
        let data = await dispatch(addContract(fd))
        if (data && !data.error) {
          form.resetFields()
          setMediaKey(moment())
        }
        events.hideDrawer()
      } else {
        notification.warning({
          message: 'Fill All Required Fields'
        })
      }
    })
  }


  return (
    <Drawer
      visible={visible}
      width={'45%'}
      placement='right'
      onClose={events.hideDrawer}
      title={'Add New Contract'}>
      <div className='form-box'>
        <div className='card unizportal'>
          <Form onSubmit={handleSubmit}>
            <Row gutter={16} className={'wrapBox'}>
              {inputTypes.fields.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {item.customField ?
                      <Col span={item.span ? item.span : 12}>
                        {item.customField}
                      </Col>
                      :
                      !item.hidden && <Col span={item.span ? item.span : 12} key={key}>
                        <GetEachFormFields
                          item={item}
                          getFieldDecorator={getFieldDecorator}
                          formItemLayout={formItemLayout} />
                      </Col>}
                  </React.Fragment>
                )
              })}
            </Row>
            <Form.Item style={{ marginTop: 25 }}>
              <Button type='primary' htmlType='submit' className={'btn'}>
                ADD
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Drawer>
  )
}
const WrappedCountry = Form.create()(AddContract)
export default WrappedCountry
