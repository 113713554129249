import React, {Suspense} from 'react'
import {connect} from 'react-redux'
import PageLoading from '../../../components/PageLoading'
import {departmentObj, cambieOnshoreUserList, adminRightUser} from '../../../components/_utils/appUtils'
import {useDispatch} from 'react-redux'
import UnizHomeEnquiryList from '../../unizHomeEnquiry/view/studentEnquiryList'

const StudentDashboard = React.lazy(() => import('./studentDashboard'))
const MasterDashboard = React.lazy(() => import('./masterDashboard'))
const UserDashboard = React.lazy(() => import('./userDashboard'))

const InterviewerDashboard = React.lazy(() => import('./interviewerDashboard'))
const WarningDashboard = React.lazy(() => import('./warningDashboard'))

const UploadTTDashboard = React.lazy(() => import('./UploadTTDashboard'))
const OxfordDashboard = React.lazy(() => import('./oxford2Dashboard'))
const OxfordInterviewDashboard = React.lazy(() => import('./oxfordInterviewDashboard'))
const OnShoreDashboard = React.lazy(() => import('./onShoreDashboard'))
const Counsellor = React.lazy(() => import('../../counsellorDashboard/view'))
const Enrolled = React.lazy(() => import('../../enrolledDashboard'))
const ReceptionDashboard = React.lazy(() =>
    import('../../ReceptionDashboard/view/index')
)

const OnlineCountForAgentComponent = React.lazy(() =>
    import('../components/onlineCountForAgentComponent.js')
)
const MarketingManagerDashboardNew = React.lazy(() => import('../../dashboardMarketing/marketingManager'))
const MarketingUserDashboardNew = React.lazy(() => import('../../dashboardMarketing/marketingUser'))

const OnShoreCanadaStudent = React.lazy(() => import('../onshoreCanada/students'))
const CourseMasterDashboard = React.lazy(() => import('../courseMaster/courseList'))
const AllAgentCommissionList = React.lazy(() => import('../../agentCommission/views/allAgentCommissionList'))
const OnshoreCambieAppList = React.lazy(() =>
    import('../../applications/cambieOnshoreApplicationList/onshoreCambieAppList')
)

const InstituteEventsList = React.lazy(() =>
    import('../../institutes/instituteEvents/InstituteEventsList')
)
const EventSlots = React.lazy(() =>
    import('../../institutes/eventSlots')
)

const CounsellingEventCalendar = React.lazy(() =>
    import('../../counselling/eventCalendar')
)
const CounsellorDashboard = React.lazy(() =>
    import('../../counselling/counsellorDashboard')
)
const GicDashboard = React.lazy(() =>
    import('../../dashboard/gic/gicList')
)
const CornerStoneOfferApplicationList = React.lazy(() => import('../../DirectColleges/cornerStoneCollege/offerApplicationList.js'))
const NationalCollegeApplicationList = React.lazy(() => import('../../DirectColleges/nationalCollege/offerApplicationList.js'))

const GicRequestCallback = React.lazy(() =>
    import('../../dashboard/list/gicRequestCallback')
)

const OnShoreApplicationListForCommission = React.lazy(() =>
    import('../../applications/onShoreApplicationForCommission/view/onShoreApplicationListForCommission')
)
const OnshoreApplicationsList = React.lazy(() =>
    import('../../applications/onshoreApplications/applicationsList')
)

const CambieDashboardComponent = React.lazy(() =>
    import('../cambieDashboard/cambieDashboardComponent')
)
const Fcmt2Dashboard = React.lazy(() => import('./fcmt2Dashboard'))
const AllRoyalArtsApplicationsList = React.lazy(() => import('../../applications/views/RoyalArtsCollege/AllRoyalArtsApplicationsList'))
const ReeudoDashboard = React.lazy(() => import('../../reeudo/views'))


const Dashboard = props => {
    let dispatch = useDispatch()
    let user = props.currentUser
    let {
        approveFCMTLoa,
        approveCambieLoa,
        showOnShoreAustralia,
        approveOxfordDraft,
        oxfordInterviewDepartment,
        enableUploadTt,
        showApprovedCommission
    } = user

    let userList = [
        'master',
        'university',
        'student',
        'branchUser',
        'reception',
        'marketingManager',
        'onshoreCanada',
        'courseMaster',
        'cambieMarketingManager',
        'institute',
        'eventManager',
        'counsellingManager',
        'unizhomeManager',
        'gicManager',
        'cornerStoneManager',
        'nationalCollegeManager',
        'gicMarketing',
        'onshoreAccountant',
        'onshoreManager',
        'directHead',
        'reeudoManager',
        ...cambieOnshoreUserList
    ]


    let counsellingFilter = (
        <>
            {user.counsellorType == 'Counsellor' ? (
                <Suspense fallback={<PageLoading/>}>
                    <Counsellor/>
                </Suspense>
            ) : (
                <Suspense fallback={<PageLoading/>}>
                    <Enrolled/>
                </Suspense>
            )}
        </>
    )

    let warningFilter = (
        <>
            {user.userType == 'branchUser' ? (
                user.department == departmentObj.warningDepartment ? ( // warning department dashboard
                    <Suspense fallback={<PageLoading/>}>
                        <WarningDashboard/>
                    </Suspense>
                ) : user.department == departmentObj.counselling ? ( // counselling department dashboard
                    <Suspense fallback={<PageLoading/>}>
                        <CounsellorDashboard/>
                    </Suspense>
                ) : user.department == departmentObj.marketing ? ( // marketing department dashboard
                    <Suspense fallback={<PageLoading/>}>
                        <MarketingUserDashboardNew/>
                    </Suspense>
                ) : user.department == departmentObj.accountDepartment ? ( // accounting department dashboard
                    <Suspense fallback={<PageLoading/>}>
                        {showApprovedCommission ? <AllAgentCommissionList/> : <UserDashboard/>}
                    </Suspense>
                ) : (// default dashboard
                    <Suspense fallback={<PageLoading/>}>
                        <UserDashboard/>
                    </Suspense>
                )
            ) : null}
        </>
    )

    let interviewFilter = (
        <>
            {user.userType == 'branchUser' ? (
                enableUploadTt ? (
                    <Suspense fallback={<PageLoading/>}>
                        <UploadTTDashboard/>
                    </Suspense>
                ) : approveFCMTLoa ? (
                    <Suspense fallback={<PageLoading/>}>
                        <Fcmt2Dashboard user={user}/>
                    </Suspense>
                ) : approveCambieLoa ? (
                    <Suspense fallback={<PageLoading/>}>

                    </Suspense>
                ) : showOnShoreAustralia ? (
                    <Suspense fallback={<PageLoading/>}>
                        <OnShoreDashboard/>
                    </Suspense>
                ) : approveOxfordDraft ? (
                    <Suspense fallback={<PageLoading/>}>
                        <OxfordDashboard/>
                    </Suspense>
                ) : oxfordInterviewDepartment ? (
                    <Suspense fallback={<PageLoading/>}>
                        <OxfordInterviewDashboard/>
                    </Suspense>
                ) : user.department == departmentObj.interviewer ? (
                    <Suspense fallback={<PageLoading/>}>
                        <InterviewerDashboard/>
                    </Suspense>
                ) : (
                    warningFilter
                )
            ) : null}
        </>
    )

    let dashboardFilter = (
        <div>

            {user.userType == 'student' && (
                <Suspense fallback={<PageLoading/>}>
                    <StudentDashboard country={user.directRegistration ? 'Canada Onshore' : 'Canada'}/>
                    {/* <StudentDashboardBackup/> */}
                </Suspense>
            )}
            {user.userType == 'master' && (
                <Suspense fallback={<PageLoading/>}>
                    <MasterDashboard/>
                </Suspense>
            )}
            {user.userType == 'courseMaster' && (
                <Suspense fallback={<PageLoading/>}>
                    <CourseMasterDashboard/>
                </Suspense>
            )}
            {user.userType == 'university' && (
                <Suspense fallback={<PageLoading/>}>

                </Suspense>
            )}
            {interviewFilter}
            {user.userType == 'reception' && (
                <Suspense fallback={<PageLoading/>}>
                    <ReceptionDashboard/>
                </Suspense>
            )}
            {(user.userType == 'marketingManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <MarketingManagerDashboardNew/>
                </Suspense>
            )}
            {(user.userType == 'onshoreCanada') && (
                <Suspense fallback={<PageLoading/>}>
                    <OnShoreCanadaStudent/>
                </Suspense>
            )}
            {(user.userType == 'onshoreManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <OnshoreApplicationsList/>
                </Suspense>
            )}
            {(user.userType == 'cambieMarketingManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <OnshoreCambieAppList/>
                </Suspense>
            )}
            {(cambieOnshoreUserList.includes(user.userType)) && (
                <Suspense fallback={<PageLoading/>}>
                    <CambieDashboardComponent user={user}/>
                </Suspense>
            )}
            {(user.userType == 'institute') && (
                <Suspense fallback={<PageLoading/>}>
                    <InstituteEventsList/>
                </Suspense>
            )}
            {(user.userType == 'eventManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <EventSlots/>
                </Suspense>
            )}
            {(user.userType == 'counsellingManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <CounsellingEventCalendar/>
                </Suspense>
            )}
            {(user.userType == 'unizhomeManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <UnizHomeEnquiryList/>
                </Suspense>
            )}

            {(user.userType == 'gicManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <GicDashboard/>
                </Suspense>
            )}

            {(user.userType == 'cornerStoneManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <CornerStoneOfferApplicationList/>
                </Suspense>
            )}
            {(user.userType == 'gicMarketing') && (
                <Suspense fallback={<PageLoading/>}>
                    <GicRequestCallback/>
                </Suspense>
            )}
            {(user.userType == 'nationalCollegeManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <NationalCollegeApplicationList/>
                </Suspense>
            )}
            {(user.userType == 'onshoreAccountant') && (
                <Suspense fallback={<PageLoading/>}>
                    <OnShoreApplicationListForCommission/>
                </Suspense>
            )}

            {(user.userType == 'directHead') && (
                <Suspense fallback={<PageLoading/>}>
                    <AllRoyalArtsApplicationsList/>
                </Suspense>
            )}

            {(user.userType == 'reeudoManager') && (
                <Suspense fallback={<PageLoading/>}>
                    <ReeudoDashboard/>
                </Suspense>
            )}

            {userList.includes(user.userType) ? null : (
                <Suspense fallback={<PageLoading/>}>
                    <UserDashboard/>
                </Suspense>
            )}
            {/* adding codes */}
        </div>
    )

    return <>
{/*        {user && (!adminRightUser.includes(user.userType) && user.userType !== 'student') ? (
            <Suspense fallback={<></>}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <OnlineCountForAgentComponent dispatch={dispatch}/>
                    </div>
                </div>
            </Suspense>
        ) : null}*/}

        {dashboardFilter}
    </>
}

const mapStateToProps = ({counter, global}) => ({
    count: counter.count,
    loading: global.buttonLoading,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard)
