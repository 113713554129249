import React, {useEffect, useRef, useState} from "react"
import {Card, Row, Col, Form, notification, Tooltip, Icon} from "antd";
import {connect, useDispatch} from "react-redux";
import {
    getFinalChangesInInrFxn,
    reeudoRatesFxn,
    reeudoSourceFxn,
    createQuotationFxn,
    allQuotationsFxn,
    singleAgentMarginFxn, getReferenceUsersFxn, currentForexStatusFxn, quotationReadStatusFxn
} from "../actions";
import {debounce} from 'lodash';
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import "./styles.less"
import {
    DefaultTablePagination,
    displayDate,
    displayTime,
    InputBox, ryanAgentId
} from "../../../components/_utils/appUtils";
import GetEachFormFields from "../../../components/_utils/appFormUtils";
import _ from "lodash"
import {TableComp} from "sz-react-utils-lite";
import AddTxnStudent from "./addTxnStudent"
import './styles.css'
import TransactionDetailsModal from "./transactionDetailsModal";
import ReeudoTimer from "../component/reeudoTimer";
import AgentTransactionModal from "./transactionModal";
import UpdatedQuotationModal from "../component/updatedQuotationModal";
import AgentTypeheadComponent from "../../../components/_utils/AgentTypeheadComponent";
import moment from "moment";
import {stringToFixed} from "../reeudoUtils";

const rupeeSymbol = "₹"
const options = {
    autoplay: false,
    loop: false,
    nav: true,
    dots: false,
    // dotClass: 'owl-dot dotNone',
    margin: 15,
    autoplayTimeout: 2000,
    infinite: false,
    speed: 1500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplaySpeed: 2000,
    responsive: {
        0: {
            items: 6,
            nav: true
        },
        600: {
            items: 6,
            nav: false
        },
        1000: {
            items: 6,
            nav: true,
            loop: false
        }
    }
    // autoWidth:true,

}

const RenderRow = (props) => {
    let {value, label, span} = props;
    return (
        <Col span={span}>
            {label}
            <h5 className={'font-weight-600'}>{value}</h5>
        </Col>
    )
}

const ReedosComponent = (props) => {
    const {form: {getFieldDecorator, setFieldsValue, getFieldValue, resetFields}, currentUserRights} = props
    let dispatch = useDispatch()
    let [reeudoData, setReeudoData] = useState({})
    let [currencyList, setCurrencyList] = useState([])
    let [sourceList, setSourceList] = useState([])
    let [selectedCurrencyObj, setSelectedCurrencyObj] = useState({})
    let [finalChangesState, setFinalChangesState] = useState({})
    let [referenceUserList, setReferenceUserList] = useState({})
    const [studentModalVisible, setStudentModalVisible] = useState(false);
    const [isAgentModal, setIsAgentModal] = useState({
        visible: false,
    });
    let [total, setTotal] = useState(0)
    const [agentData, setAgentData] = useState({});
    const [selectedSourceId, setSelectedSourceId] = useState(2)
    let [transactionDrawer, setTransactionDrawer] = useState({
        visible: false,
        id: ''
    })
    let [transactionModal, setTransactionModal] = useState({
        visible: false,
        quotation: {},
        // orgYourRate: ''
    })

    let [updatedQuotationState, setUpdatedQuotationState] = useState({
        quotation: {},
        visible: false,
        exchangeMargin: 0
    })


    const [agentMargin, setAgentMargin] = useState(undefined);
    const [agentId, setAgentId] = useState(ryanAgentId);
    const [isModalVisible, setModalVisible] = useState(false);
    const [modalSource, setModalSource] = useState(null);
    const [isForexOff, setIsForexOff] = useState(false);
    const [refreshKey, setRefreshKey] = useState(moment());
    const [loading, setLoading] = useState(true);
    const tableRef = useRef();
    let user = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user'))
        : null
    const currentForexStatus = async () => {
        try {
            setLoading(true);
            const resp = await dispatch(currentForexStatusFxn());
            if (resp.status === 'off') {
                setIsForexOff(true);
            } else {
                setIsForexOff(false);
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    const showAgentModal = () => {
        setIsAgentModal({
            visible: true,
        });
    };

    const closeAgentModal = () => {
        setIsAgentModal({
            visible: false,
        });
    };

    const updateReadStatus = async (id, isReaded) => {
        if (isReaded) {
            // console.log('Already read, no need to update.');
            return;
        }
        const resp = await dispatch(quotationReadStatusFxn({id: id}));
    };
    const events = {
        setDefaultAgent: () => {
            if (currentUserRights && currentUserRights.userType == "agent") {
                setAgentId(currentUserRights._id)
            } else {
                setAgentId(ryanAgentId)
            }
            setRefreshKey(moment())
        },
        getFlag: (item) => {
            let url = `https://static-content-prd-reeudo.s3.ap-south-1.amazonaws.com/country-flags/${item}2x.png`
            return url
        },
        loadRates: async () => {
            let {data, success} = await dispatch(reeudoRatesFxn());
            console.log(data);

            let {exchangeMargin = 0} = currentUserRights;
            if (success) {
                let dataArr = [];
                let {currencyData, currencyOrder} = data;

                _.each(currencyOrder, (item) => {
                    let url = `https://static-content-prd-reeudo.s3.ap-south-1.amazonaws.com/country-flags/${item}2x.png`;
                    let resp = currencyData && currencyData[item] ? currencyData[item] : null;
                    const updatedRate = (parseFloat(resp.yourRate) + parseFloat(exchangeMargin) + parseFloat(agentMargin)).toFixed(2);

                    let obj = {
                        currency: item,
                        ...resp,
                        imgPath: url,
                        yourRate: updatedRate,

                    };
                    dataArr.push(obj);
                });
                // console.log(dataArr, "dataarr")
                setCurrencyList(dataArr);
                setReeudoData(data);
                if (dataArr.length) {
                    const defaultCurrency = dataArr[0];
                    setFieldsValue({
                        amount: 100,
                        currency: defaultCurrency.currency,
                        customerRate: ((parseFloat(defaultCurrency.yourRate) || 0)).toFixed(2), // Set customerRate from yourRate
                    });
                    setSelectedCurrencyObj(defaultCurrency);
                }

                events.setDefaultParams();
            }
        },

        loadSource: async () => {
            let {data, success} = await dispatch(reeudoSourceFxn())
            if (success) {
                setSourceList(data);
            }
        },
        setDefaultParams: () => {
            let currency = getFieldValue('currency');
            if (!currency && currencyList.length) {
                const defaultCurrency = currencyList[0]; // Use the first currency as default
                setTimeout(() => {
                    setFieldsValue({
                        amount: 100,
                        currency: defaultCurrency.currency,
                        customerRate: ((parseFloat(defaultCurrency.yourRate) || 0)).toFixed(2), // Ensure you are using yourRate from currencyList
                    });
                    setTimeout(() => {
                        events.getFinalChangesFxn();
                    }, 100);
                }, 100);
                setSelectedCurrencyObj(defaultCurrency);
            }
        },
        setDefaultSource: () => {
            setTimeout(() => {
                setFieldsValue({
                    sourceId: sourceList && sourceList.length ? sourceList[1].id : "",
                })
            }, 100)
        },
        chooseCurrency: (currency) => {
            let findDoc = _.find(currencyList, (item) => item.currency === currency);
            if (findDoc) {
                setSelectedCurrencyObj(findDoc);
                setTimeout(() => {
                    setFieldsValue({
                        customerRate: findDoc ? ((parseFloat(findDoc.yourRate) || 0)).toFixed(2) : "", // Get yourRate from findDoc
                    });
                }, 100);
            }
        },
        getFinalChangesFxn: async () => {
            let customerRate = getFieldValue('customerRate') || 0
            if (typeof customerRate == "string") {
                customerRate = parseFloat(customerRate)
            }
            customerRate = customerRate.toFixed(2)
            let obj = {
                currency: getFieldValue('currency'),
                amount: getFieldValue('amount'),
                customerRate: customerRate,
                sourceId: getFieldValue('sourceId'),
            }
            if (obj.currency && obj.amount && obj.customerRate && obj.sourceId) {
                let {data, success} = await dispatch(getFinalChangesInInrFxn(obj))
                console.log(data.charges);
                if (success && data && data.charges) {
                    setFinalChangesState(data.charges)
                }
            }
        },
        showTransactionModal: (quotation) => {
            setTransactionModal({
                visible: true,
                quotation: quotation
            });
        },
        hideTransactionModal: () => {
            setTransactionModal({
                visible: false,
                quotation: {}
            });
        },
        // createAgent: async () => {
        //     let obj = {
        //         email: user.email,
        //         fname: user.name,
        //         lname: "",
        //         phone: user.mobile,
        //         grpIds: [
        //             "fd8b4f5d-27dc-4e84-b7a7-01ef5bdece9c",
        //             "f79df9e8-a80d-4c9b-bb10-a6368b22f58b",
        //             "ed24eb7a-53ef-4fbf-ad3c-7e21fe58e425",
        //             "9b2f733d-1454-4863-a719-56a1116f2cca",
        //             "51f3e22f-b7d9-4a53-a258-a86694890fdc",
        //             "633f0c5c-1f42-4929-8df1-cdfd8f9b8487",
        //             "b768a411-bf4c-4ba9-b28f-66ba9cdc422e"
        //         ]
        //     }
        //     let {data, success} = await dispatch(createAgentFxn(obj))
        //     console.log(data, "datat")
        //     if (data) {
        //         notification.success({message: "Agent created successfully"});
        //     } else {
        //         notification.error({message: "Error creating agent"});
        //     }
        // },
        singleAgent: async (agentId) => {
            let data = await dispatch(singleAgentMarginFxn({userId: agentId}));
            if (data && data.agentMargin) {
                setAgentMargin(data.agentMargin);
            } else {
                setAgentMargin(0);
            }

        },
        createQuotation: async (formData) => {
            const finalChanges = finalChangesState || {};
            const obj = {
                userId: "",
                amount: parseFloat(finalChanges.fromCurrencyAmount).toFixed(2) || 0,
                bankFees: parseFloat(finalChanges.bank).toFixed(2) || 0,
                yourRate: parseFloat(finalChanges.yourRate).toFixed(2) || null,
                ibrRate: parseFloat(finalChanges.ibrRate).toFixed(2) || null,
                currentRate: parseFloat(finalChanges.fxRate).toFixed(2) || null,
                fxRate: parseFloat(finalChanges.fxRate).toFixed(2) || null,
                emailId: formData ? formData.emailAddress : "",
                pan: null,
                fromCurrency: finalChanges.fromCurrency || null,
                grossAmount: parseFloat(finalChanges.base).toFixed(2) || 0,
                gst: parseFloat(finalChanges.gst).toFixed(2) || 0,
                name: formData ? formData.studentName : "",
                nostroCharge: parseFloat(finalChanges.nostro).toFixed(2) || 0,
                tcsCharge: parseFloat(finalChanges.tcs).toFixed(2) || 0,
                phoneNo: formData ? formData.phoneNumber : "",
                toCurrency: finalChanges.toCurrency || null,
                totalAmount: parseFloat(finalChanges.total).toFixed(2) || 0,
                consultantName: null,
                consultantId: null,
                sourceId: formData.sourceId ? formData.sourceId : 1,
                agentId: formData.agentId ? formData.agentId : user._id,
            };
            if (formData && formData.referencedBy && formData.referencedBy !== "NA") {
                obj.referencedBy = formData.referencedBy;
            }

            let resp = await dispatch(createQuotationFxn(obj));
            if (resp.success) {
                closeModal()
                tableRef.current.reload()
                notification.success({message: 'Quotation created successfully'});
                events.showTransactionModal(resp.quotationData)
            }
        },
        apiRequest: (params) => {
            return new Promise(async (resolve) => {
                params.sortField = '_id'
                params.sortOrder = 'descend'
                let resp = await dispatch(allQuotationsFxn(params))
                setTotal(resp.total)
                resolve(resp)
                // setTotal(resp.total)
                // resolve(resp);
            });
        },
        loadReferUsers: async () => {
            let {data} = await getReferenceUsersFxn({agentId});
            let finArr = [{_id: "NA", name: "NA"}]
            if (data && data.length) {
                _.each(data, (item) => {
                    let postName = item.branchManagerType == "agentWise" ? "ARM" : "Marketing User"
                    item.name = `${item.name} (${postName})`
                })
                finArr = [...finArr, ...data]
            }
            setReferenceUserList(finArr);
        },

        showUpdateQuoModal: (data) => {
            setUpdatedQuotationState({
                quotation: data,
                visible: true,
                exchangeMargin: currentUserRights.exchangeMargin
            })
        },
        hideUpdateQuoModal: () => {
            setUpdatedQuotationState({
                quotation: {},
                visible: false,
                exchangeMargin: currentUserRights.exchangeMargin
            })
        }

    }

    const debouncedGetFinalChangesFxn = debounce(events.getFinalChangesFxn, 300);
    const showModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };


    useEffect(() => {
        currentForexStatus();
        events.setDefaultAgent();
    }, []);
    useEffect(() => {
        setAgentMargin(currentUserRights.agentMargin || 0);
    }, [currentUserRights]);

    useEffect(() => {
        // if (loading || isForexOff) return;
        window.scrollTo(0, 0);
        events.loadSource();
        // events.singleAgent(ryanAgentId)
    }, []);


    useEffect(() => {
        if (agentId) {
            events.loadReferUsers();
        }
    }, [agentId]);

    useEffect(() => {
        // if (loading || isForexOff) return;
        if (agentMargin !== undefined) {
            events.loadRates();
        }
    }, [agentMargin]);

    useEffect(() => {
        // if (loading || isForexOff) return;
        events.setDefaultParams();
    }, []);

    useEffect(() => {
        // if (loading || isForexOff) return;

        events.setDefaultSource();
    }, [sourceList]);

    useEffect(() => {
        // if (loading || isForexOff) return;
        debouncedGetFinalChangesFxn();
    }, [
        getFieldValue('currency'),
        getFieldValue('amount'),
        getFieldValue('customerRate'),
        getFieldValue('sourceId'),
        agentMargin
        // isForexOff,
        // loading
    ]);

    let inputTypes = {
        fields: [
            {
                key: 'currency',
                label: 'Currency',
                type: 'select',
                required: true,
                options: currencyList,
                keyAccessor: x => x.currencyCode,
                valueAccessor: (x) => {
                    return (
                        <>
                            <img src={x.imgPath} style={{height: 25}}/>
                            <span className={'ml20 font-weight-600'}>{x.currencyCode} - {x.currencyName}</span>
                        </>
                    )
                },
                onChange: x => {
                    events.chooseCurrency(x);
                    setFieldsValue({
                        currency: x,
                    })
                }
            },
            {key: 'customerRate', label: 'Customer Rate', required: true, type: 'number'},
            {key: 'amount', label: 'Amount', required: true},
            {
                key: 'sourceId',
                label: 'Source',
                type: 'select',
                required: true,
                options: sourceList,
                keyAccessor: x => x.id,
                valueAccessor: (x) => x.sourceOfFund,
                onChange: x => {
                    events.chooseCurrency(x);
                    setFieldsValue({
                        sourceId: x,
                    })
                }
            },
        ]
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }

    const ViewQuoFxn = (record) => {
        return (
            <div style={{paddingLeft: 10}}>
                <a className={'btn roundNew xs btn-default mt5'}
                   onClick={() => {
                       events.showTransactionModal(record)
                       setModalSource('isReaded');
                   }}>
                    View
                </a>
            </div>
        )
    }


    const columns = [
        {
            title: 'Sr No.',
            dataIndex: 'serialNo',
            width: 70,
            render: (val, record, index) => <div>{index + 1}</div>
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (text) => <span>{displayDate(text, 'DD/MM/YYYY')}</span>,
        },
        {
            title: 'Quotation Id',
            dataIndex: 'quotationId',
            render: (text, record) => (
                <div>


                    <div>
                        {user && user.userType == "reeudoManager" ?
                            <>
                                <div style={{fontWeight: record.isReaded ? 'normal' : 'bold'}} className={'jcic'}>
                                    {record.isReaded ? null : <div className={'roundDot mr5'}></div>}
                                    {text}
                                </div>


                                {!record.isReaded ? (
                                    <a className={'btn roundNew xs btn-primary mt5 ml10'}
                                       onClick={() => {
                                           updateReadStatus(record._id, record.isReaded);
                                           events.showTransactionModal(record)
                                           setModalSource('isReaded');
                                       }}>
                                        Read
                                    </a>
                                ) : ViewQuoFxn(record)}
                            </> :
                            <>
                                <div className={'jcic'}>
                                    {text}
                                </div>
                                {ViewQuoFxn(record)}
                            </>}

                    </div>

                </div>
            ),
        },

        {
            title: 'Agent Name',
            dataIndex: 'agentId.name',
            width: 150,
            render: (text, record) => (
                <div>
                    {text}
                </div>
            ),
        },
        // {
        //     title: 'Consultant',
        //     dataIndex: 'consultantName',
        //     render: (text) => <span>{text}</span>,
        // },
        {
            title: 'From Currency',
            dataIndex: 'fromCurrency',
            render: (text) => <span>{text}</span>,
        },

        {
            title: 'Your Rate',
            dataIndex: 'yourRate',
            render: (text, record) => (
                <span>{(parseFloat(record.exchangeMargin) + text).toFixed(2)}</span>
            ),
        },

        {
            title: 'Booking Rate',
            dataIndex: 'currentRate',
            render: (text) => <span>{text}</span>,
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Gross Amount',
            dataIndex: 'grossAmount',
            render: (item) => {
                return stringToFixed(item)
            }
        },
        {
            title: 'Assigned User',
            key: 'assignedUserId',
            dataIndex: 'assignedUserId',
            width: 240,
            render: (item) => {
                return (
                    <div>
                        {item && item.name ? <div>{item.name}</div> : ""}
                        {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ""}
                        {item && item.email ? <div><b>Email-</b>{item.email}</div> : ""}
                    </div>
                );
            }
        },
        {
            title: 'Referenced By',
            key: 'referencedBy',
            dataIndex: 'referencedBy',
            width: 240,
            render: (item) => {
                return (
                    <div>
                        {item && item.name ? <div>{item.name}</div> : ""}
                        {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ""}
                        {item && item.email ? <div><b>Email-</b>{item.email}</div> : ""}
                    </div>
                );
            }
        },
        {
            title: 'Action',
            dataIndex: 'quotationPdf',
            fixed: 'right',
            render: (text, record) => (
                <>
                    {record.quotationPdf && record.quotationPdf.path ? (
                        <Tooltip title={'Download Quotation'}>
                            <a download={record.quotationPdf.fileName}
                               className={'btn roundNew xs btn-default'}
                               href={record.quotationPdf.path}
                                // onClick={() =>  updateReadStatus(record._id, record.isReaded)}
                               target="_blank">
                                <Icon type={'download'}/><br/>
                                Download
                            </a>
                        </Tooltip>
                    ) : null}

                    <Tooltip title={' Get Updated Details - Quotation & Book Transaction'}>
                        <a className={'btn roundNew xs btn-default ml10'} onClick={() => {
                            events.showUpdateQuoModal(record)
                        }}>
                            Get Updated Details<br/>
                            Quotation & Book Transaction
                        </a>
                    </Tooltip>
                </>
            ),
        }


    ];
    const fixed2Digit = (value) => {
        const num = parseFloat(value) || 0;
        return num.toFixed(2);
    };


    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>

                    <div className='card'>

                        <div className=''>

                            <div className={'jcsb'} style={{padding: '1px 32px'}}>
                                <div className={'jcsb'}>
                                    <div className={'mr50'}>
                                        IBR Rate<br/>
                                        <strong>{reeudoData.date ? displayDate(reeudoData.date, 'DD/MM/YYYY') : null}</strong>
                                    </div>
                                    <div className={'mr50'}>
                                        Last Updated<br/>
                                        <strong>{reeudoData.date ? displayTime(reeudoData.date, 'hh:mm:ss a') : null}</strong>
                                    </div>
                                    <ReeudoTimer callback={() => {
                                    }}/>
                                </div>

                                {user && user.userType !== "agent" ? (
                                    <div key={refreshKey} className={'w-25'}>
                                        <InputBox title={'* Choose Agent'}>
                                            <AgentTypeheadComponent
                                                dispatch={dispatch}
                                                customStyle={''}
                                                agentId={agentId}
                                                // defaultValue={ryanAgentId}
                                                onSelect={item => {
                                                    setAgentId(item);
                                                    events.singleAgent(item)

                                                }}
                                            />
                                        </InputBox>
                                    </div>
                                ) : null}
                            </div>

                        </div>
                        <div className='card-body table-responsive'>
                            <div className={'currency-list reeudo-slider'}>
                                <OwlCarousel
                                    className="owl-theme "
                                    {...options}>
                                    {currencyList && currencyList.length ? currencyList.map((item) => {
                                        return (
                                            <Card className={'currency-card'}>
                                                <div className={'jcsb'}>
                                                    <div>
                                                        <div>
                                                            Live IBR / <span
                                                            className={'currency-name'}>{item.currency}</span>
                                                        </div>
                                                        <div className={'currency-value'}>
                                                            {item.rate}
                                                        </div>
                                                    </div>
                                                    <img src={events.getFlag(item.currency)}
                                                         style={{height: 25, width: "auto"}}/>
                                                </div>
                                                <div className={'your-rate'}>
                                                    Your Rate<br/>
                                                    <div className={'rate'}>
                                                        {item.yourRate}
                                                    </div>
                                                </div>
                                            </Card>
                                        )
                                    }) : null}
                                </OwlCarousel>
                            </div>
                        </div>
                        <div className='card-body table-responsive'>
                            <Row gutter={24}>
                                {inputTypes.fields.map((item, key) => {
                                    return (
                                        <Col span={6} key={key}>
                                            <GetEachFormFields
                                                item={item}
                                                getFieldDecorator={getFieldDecorator}
                                                formItemLayout={formItemLayout}/>
                                        </Col>
                                    )
                                })}
                                {/*<Col span={4} className={'mt40'}>*/}
                                {/*    <a className={'btn roundNew btn-default btn-block lg'} onClick={() => {*/}
                                {/*        events.getFinalChangesFxn()*/}
                                {/*    }}><Icon type={'search'}/> Search</a>*/}
                                {/*</Col>*/}
                            </Row>
                            <Row className={'mt20'} gutter={15}>
                                <Col span={18}>
                                    <Card>
                                        <Row>
                                            <RenderRow
                                                label={'Currency Type'}
                                                value={finalChangesState && finalChangesState.fromCurrency ? finalChangesState.fromCurrency : ""}
                                                span={7}
                                            />
                                            <RenderRow
                                                label={'Foreign Currency Amount'}
                                                value={finalChangesState && finalChangesState.fromCurrencyAmount ? finalChangesState.fromCurrencyAmount : ""}
                                                span={6}
                                            />
                                            <RenderRow
                                                label={'Rate'}
                                                value={finalChangesState && finalChangesState.fxRate ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.fxRate)}` : `${rupeeSymbol} 0.00`}
                                                span={5}
                                            />
                                            <RenderRow
                                                label={'Gross Amount'}
                                                value={finalChangesState && finalChangesState.base ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.base)}` : `${rupeeSymbol} 0.00`}
                                                span={5}
                                            />
                                        </Row>
                                        <Row className={'mt10'}>
                                            <RenderRow
                                                label={'Foreign Conversion GST'}
                                                value={finalChangesState && finalChangesState.gst ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.gst)}` : `${rupeeSymbol} 0.00`}
                                                span={7}
                                            />
                                            <RenderRow
                                                label={'Bank Processing Fee'}
                                                value={finalChangesState && finalChangesState.bank ? `${rupeeSymbol} ${finalChangesState.bank}` : `${rupeeSymbol} 0`}
                                                span={6}
                                            />
                                            <RenderRow
                                                label={'Nostro Charge'}
                                                value={finalChangesState && finalChangesState.nostro ? `${rupeeSymbol} ${finalChangesState.nostro}` : `${rupeeSymbol} 0`}
                                                span={5}
                                            />
                                            <RenderRow
                                                label={'TCS Charge*'}
                                                value={finalChangesState && finalChangesState.tcs ? `${rupeeSymbol} ${fixed2Digit(finalChangesState.tcs)}` : `${rupeeSymbol} 0.00`}
                                                span={5}
                                            />
                                        </Row>

                                        <div className={'mt5 gray-color'}>
                                            <small className={'mt1'}>*The Final TCS may change based on the historical
                                                transactions of the
                                                sender and the "Source of Fund"</small>
                                        </div>
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card>
                                        <div>
                                            Grand Total<br/>
                                            <h4 className={'font-weight-600 colorPrimaryDark'}>
                                                {rupeeSymbol} {finalChangesState && finalChangesState.total ? fixed2Digit(finalChangesState.total) : 0}
                                            </h4>
                                        </div>
                                        <a
                                            className={'btn btn-primary roundNew lg mt20 w-100'}
                                            onClick={() => {
                                                const customerRate = getFieldValue('customerRate');
                                                const yourRate = selectedCurrencyObj.yourRate;
                                                const selectedSourceId = getFieldValue('sourceId'); // Get the selected sourceId

                                                if (parseFloat(customerRate) < parseFloat(yourRate)) {
                                                    notification.warning({
                                                        message: 'Warning',
                                                        description: `Customer rate cannot be less than your rate ${yourRate}`,
                                                    });
                                                    return;
                                                }

                                                showModal();
                                                setModalSource('quote');

                                                setSelectedSourceId(selectedSourceId);
                                            }}
                                        >
                                            Get Quote & Book Transaction
                                        </a>


                                    </Card>
                                    {/*<div>*/}
                                    {/*    <a className={'btn btn-primary roundNew lg mt10 w-100'}*/}
                                    {/*       onClick={() => {*/}
                                    {/*           setTransactionDrawer({*/}
                                    {/*               visible: true,*/}
                                    {/*               id: ''*/}
                                    {/*           })*/}
                                    {/*       }}>*/}
                                    {/*        Book Transaction*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-3'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center'>
                            <h5>Recent Quotations: (Total : {total})</h5>
                        </div>
                        <div className='card-body table-responsive'>

                            <TableComp
                                apiRequest={events.apiRequest}
                                columns={columns}
                                ref={tableRef}
                                extraProps={{scroll: {x: 600}}}
                                pagination={{
                                    position: 'top',
                                    pageSizeOptions: ['10', '20', '25', '50'],
                                    defaultPageSize: 10
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isModalVisible ?
                <AddTxnStudent
                    {...props}
                    visible={isModalVisible}
                    agentId={agentId}
                    isForexOff={isForexOff}
                    onClose={closeModal}
                    referenceUserList={referenceUserList}
                    onSubmit={events.createQuotation}
                    sourceId={selectedSourceId}
                /> : null}

            {
                transactionModal.visible ? (
                    <TransactionDetailsModal
                        visible={transactionModal.visible}
                        quotation={transactionModal.quotation}
                        agentMargin={agentMargin}
                        isForexOff={isForexOff}
                        onClose={() => {
                            events.hideTransactionModal()
                            showAgentModal();
                        }}
                        onSkip={() => {
                            events.hideTransactionModal()
                            tableRef.current.reload()
                        }}
                        modalSource={modalSource}
                    />
                ) : null
            }
            {
                isAgentModal.visible ? (
                    <AgentTransactionModal
                        visible={isAgentModal.visible}
                        onClose={closeAgentModal}

                    />
                ) : null
            }
            {updatedQuotationState.visible ? (
                <UpdatedQuotationModal
                    {...updatedQuotationState}
                    isForexOff={isForexOff}
                    agentMargin={agentMargin}
                    onClose={events.hideUpdateQuoModal}
                />
            ) : null
            }
        </>
    )
}

const WrapReedosComponent = Form.create()(ReedosComponent)

const mapStateToProps = ({global}) => ({
    currentUserRights: global.currentUserRights,
    currentUser: global.currentUser,
})
export default connect(
    mapStateToProps,
    null
)(WrapReedosComponent)

