import moment from 'moment'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Drawer,
  Card,
  Col, Tooltip
} from 'antd'
import { transactionListFxn } from './gicCommission/action'
import WithDrawCommission from './withDrawCommission'
import { DefaultTablePagination, longDisplayDate, displayDate } from '../../../components/_utils/appUtils'
import { TableComp } from 'sz-react-utils-lite'
import { RowTable } from '../../../components/_utils/RowTable'

let agentCommissionInit = {
  visible: false,
  expenseId: {}
}
const TransactionListGic = (props) => {
  let tableRef = useRef()
  let dispatch = useDispatch()
  let { visible, currentBalance, user, agentId = '' } = props
  let userData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
  let [visibleWithdrawal, setVisibleWithdrawal] = useState(false)
  const [singleExpenseState, setSingleExpenseState] = useState(agentCommissionInit)
  const [refreshKey, setRefreshKey] = useState(moment())


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 100,
      render: (val, record, index) => {
        return index + 1
      }
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => {
        return <>{longDisplayDate(text)}</>
      }
    },

    {
      title: 'Transaction/Reference',
      dataIndex: 'transactionReference',
      key: 'transactionReference',
      width: 500,
      render: (text, item) => {
        let {
          withdrawalId,
          invoiceNo
        } = item
        return (
          <>
            {item.transactionType === 'Credit' ?
              <>
                {invoiceNo}
              </> :
              <>
                {
                  withdrawalId && withdrawalId.account ?
                    <div>
                      Acc no
                      : {withdrawalId.account.accountNo} ({withdrawalId.account.bankName}) <br />
                    </div> : null
                }
              </>
            }

            {item.transactionType == 'Credit' ? <>
              <div>
                {item.gicId ? <>
                  {item.gicId.firstName} {item.gicId.lastName} ({item.gicId.email})
                </> : item.note}
              </div>
            </> : ''}

            {item.transactionType == 'Debit' ? <>
              <div className={'font12'}>
                {
                  withdrawalId && withdrawalId.receipt && withdrawalId.receipt.url ?
                    <>
                      <a href={withdrawalId.receipt.url} target={'_blank'}>
                        Download Receipt</a>

                      {withdrawalId.invoice && withdrawalId.invoice.path ?
                        <>
                          <a href={withdrawalId.invoice.path} target={'_blank'}
                             className={'ml20'}>Download
                            Invoice</a>
                        </>
                        : null}
                    </> :
                    <>
                      <div className={'font11'}> Withdrawal : <label
                        className={'label label-warning xs1'}>Pending</label></div>
                    </>
                }
              </div>
            </> : ''}


          </>
        )
      }
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      render: (text, record) => {
        if (record.transactionType === 'Debit') {
          return (
            <div style={{ textAlign: 'left' }}>
              <span className='debit2'>${record.amount.toFixed(2)}</span>
            </div>
          )
        } else {
          return null
        }
      }
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      render: (text, record) => {
        if (record.transactionType === 'Credit') {
          return (
            <div style={{ textAlign: 'left' }}>
              <span className='credit2'>${record.amount.toFixed(2)}</span>
            </div>
          )
        } else {
          return null
        }
      }
    }
  ]

  const events = {
    showWithdrawal: () => {
      setVisibleWithdrawal(true)
    },
    hideWithdrawal: () => {
      setVisibleWithdrawal(false)
      setRefreshKey(moment())
    },
    showSingleExpense: (record) => {
      setSingleExpenseState({
        visible: true,
        expenseId: record
      })
    },
    hideSingleExpense: () => {
      setSingleExpenseState({
        visible: false,
        expenseId: ''
      })
    },
    reload: () => {
    },
    apiRequest: (params) => {
      return new Promise(async (resolve) => {
        let obj = {
          sortField: '_id',
          sortOrder: 'descend',
          ...params,
          results: 100000
        }
        if (agentId) {
          obj.agentId = agentId
        }
        let data = await dispatch(
          transactionListFxn({
            ...obj,
            regExFilters: ['type', 'name', 'shortName']
          })
        )
        resolve(data)
      })
    }
  }

  return (
    <>
      <Drawer
        title={
          userData.userType === 'agent' ? (
            <div key={currentBalance ? currentBalance : null}>
              Transaction list ({`Balance : $${currentBalance ? currentBalance.toFixed(2) : null}`})
              <a
                className='btn btn-default btn-sm ml10'
                onClick={() => {
                  events.showWithdrawal()
                }}
              >
                Withdraw Commission
              </a>
            </div>
          ) : (
            <div>
              Transaction list
            </div>
          )
        }
        onClose={() => {
          props.onSubmit()
          props.onClose()
        }}
        visible={visible}
        width={'75%'}
      >
        <div className='col-lg-12'>
          <div className='card'>
            <div className='card-body table-responsive'>
              <TableComp
                apiRequest={events.apiRequest}
                columns={columns}
                size='large'
                pagination={DefaultTablePagination()}
                ref={tableRef}
              />
            </div>
          </div>
        </div>
      </Drawer>
      {visibleWithdrawal ? (
        <WithDrawCommission
          user={user}
          visible={visibleWithdrawal}
          onClose={events.hideWithdrawal}
          onSubmit={() => {
            events.hideWithdrawal()
            tableRef.current.reload()
            setRefreshKey(moment())
            props.onSubmit()
            props.getRelatedUser()
          }}
          currentBalance={currentBalance}
        />
      ) : null}
    </>
  )
}
export default TransactionListGic
