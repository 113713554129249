import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, notification, Row} from 'antd';
import {useDispatch} from 'react-redux';
import {singleAgentMarginFxn, updateAgentMarginFxn} from "../actions";
import {InputBox} from "../../../components/_utils/appUtils";

const UpdateAgentMargin = (props) => {
    let {user} = props;
    const dispatch = useDispatch();
    const [agentMargin, setAgentMargin] = useState(0.0);
    const fetchAgentMargin = async () => {
        const data = await dispatch(singleAgentMarginFxn({userId: user._id}));
        if (data) {
            setAgentMargin(data.agentMargin);
        }
    };

    const handleSubmit = async () => {
        if (!agentMargin) {
            notification.success({
                message: 'Enter exchange margin.',
            });
            return
        }
        const payload = {
            userId: user._id,
            agentMargin: agentMargin,
        };
        const resp = await dispatch(updateAgentMarginFxn(payload));
        if (resp.success) {
            dispatch({
                type: 'START_LOAD_CURRENT_USER'
            })
            notification.success({
                message: 'Agent margin updated successfully!',
            });
        } else {
            notification.error({
                message: resp.message,
            });
        }
    };

    useEffect(() => {
        fetchAgentMargin();
    }, []);

    return (
        <div className="row">
            <div className="col-lg-12">
                <Form>
                    <div className="form-box mt-4">
                        <div className="d-flex align-items-center mb-3 heading-form">
                            <h5>Update Forex Margin</h5>
                        </div>

                        <div className="card unizportal">
                            <Row gutter={24}>
                                <Col span={8}>

                                    <InputBox title={'Forex Margin'}>
                                        <Input
                                            type={'text'}
                                            value={agentMargin}
                                            className={'form-control'}
                                            placeholder="Enter forex margin"
                                            onChange={(e) => setAgentMargin(e.target.value)}
                                        />
                                    </InputBox>
                                </Col>
                            </Row>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" className="btn" onClick={() => {
                                    handleSubmit()
                                }}>
                                    Update
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default UpdateAgentMargin;
