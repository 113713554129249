import { Icon, Input, Modal, notification, Tooltip } from 'antd'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TableComp } from 'sz-react-utils-lite'
import { DefaultTablePagination, InputBox } from '../../components/_utils/appUtils'
import TextArea from 'antd/es/input/TextArea'
import moment from 'moment'
import { approveAgentMeetingFxn, getAllMeetingsFxn, updateMeetingStatus } from './actions'
import _ from 'lodash'

const AgentMeetingList = props => {
  let tableRef = useRef()
  const dispatch = useDispatch()
  const [state, setState] = useState({
    meetingPhotos: []
  })
  console.log(state, 'state')

  const initialState = {
    visible: false,
    travelPlanId: '',
    agentId: '',
    meetingId: '',
    user: '',
    status: ''
  }
  const [detailsModal, setDetailsModal] = useState(initialState)
  const [cancelModal, setCancelModal] = useState(initialState)
  const [details, setDetails] = useState('')
  const [reason, setReason] = useState('')


  let apiRequest = (params) => {
    return new Promise(async (resolve) => {
      let obj = {}
      params = { ...params, ...obj }
      let data = await dispatch(getAllMeetingsFxn({ ...params }))
      resolve(data)
    })
  }

  const updateMeeting = async (meetingId, status) => {
    if (status == 'Cancelled' && !reason) {
      notification.warning({ message: 'Enter reason' })
      return
    }
    let data = await dispatch(updateMeetingStatus({ meetingId, status, details, reason }))
    if (data) {
      setDetailsModal({ visible: false, meetingId: '' })
      setCancelModal({ visible: false, meetingId: '' })
      tableRef.current.reload()
    }
  }

  const updateMeetingPhotos = async (meetingId, status) => {
    let fd = new FormData()
    let obj = {}
    obj.meetingId = meetingId
    obj.status = status
    fd.append('obj', JSON.stringify(obj))

    if (state.meetingPhotos && state.meetingPhotos.length) {
      _.each(state.meetingPhotos, (item, key) => {
        fd.append('meetingPhotos', item)
      })
    } else {
      notification.warning({ message: 'Please select meeting photos' })
    }

    let data = await dispatch(approveAgentMeetingFxn(fd))
    if (data) {
      setDetailsModal({ visible: false, meetingId: '' })
      setCancelModal({ visible: false, meetingId: '' })
      tableRef.current.reload()
    }
  }

  const chooseFiles = (files, type) => {
    setState({
      ...state,
      [type]: files
    })
  }

  const columns = [
    {
      title: 'Sr. no.',
      key: '_id',
      dataIndex: '_id',
      render: (item, record, index) => {
        return <div style={{ width: 50 }}>{index + 1}</div>
      }
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      render: (item, record, index) => {
        return <div style={{ width: 100 }}>{moment(item).format('DD/MM/YYYY')}</div>
      }
    },
    {
      title: 'Agent',
      dataIndex: 'agentId.name',
      key: 'agentId.name'
    },
    {
      title: 'Added By User',
      dataIndex: 'addedByUserId.name',
      key: 'addedByUserId.name'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (item, record, index) => {
        return (
          <div style={{
            padding: '10px',
            backgroundColor: item === 'Cancelled' ? '#ffe6e6' : '#f0f0f0',
            borderRadius: '8px',
            border: item === 'Cancelled' ? '1px solid #ff4d4f' : '1px solid #d9d9d9',
            color: item === 'Cancelled' ? '#ff4d4f' : '#000',
            fontWeight: 500
          }}>
            {item === 'Cancelled' ? (
              <div>
                Cancelled <br />
                <b>Reason: </b>{record.reason || 'Not provided'}
              </div>
            ) : (
              <div>{item}</div>
            )}
          </div>

        )
      }
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details'
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      render: (item, record) => {
        return (
          <>
            <div className={'btn_group'} style={{ width: 190 }}>
              {
                record.status == 'Pending' &&
                <>
                  <Tooltip title='Approve'>
                    <button className='btn'
                            onClick={() => {
                              setDetailsModal({
                                visible: true,
                                meetingId: record._id,
                                status: 'Approved'
                              })
                            }}
                    >
                      <Icon type={'check'} />
                    </button>
                  </Tooltip>
                  <Tooltip title='Cancel'>
                    <button className='btn'
                            onClick={() => {
                              setCancelModal({
                                visible: true,
                                meetingId: record._id,
                                status: 'Cancel'
                              })
                            }}>
                      <Icon type={'cross'} />
                    </button>
                  </Tooltip>
                </>
              }
            </div>
          </>
        )
      }
    }
  ]

  return (
    <>
      <div className='row mt-4'>
        <div className='col-lg-12'>
          <div className='card'>
            <div className='table-head d-flex align-items-center'>
              <h5>Meeting List</h5>
              <div className='search-box-table' />
            </div>

            <div className='card-body table-responsive mt10'>
              <TableComp
                columns={columns}
                apiRequest={apiRequest}
                ref={tableRef}
                pagination={DefaultTablePagination()}
              />
            </div>
            {
              detailsModal.visible ? (
                <Modal
                  title='Enter Detail'
                  visible={detailsModal.visible}
                  onOk={() => {
                    updateMeetingPhotos(detailsModal.meetingId, 'Approved')
                  }}
                  onCancel={() => {
                    setDetailsModal({ ...detailsModal, visible: false })
                  }}
                >
                  <InputBox title={'Upload Meeting Images*'}>
                    <Input type={'file'} multiple={true} name={'meetingPhotos'} id={'meetingPhotos'}
                           key={state.meetingPhotos}
                           className={'form-control'}
                           onChange={(e) => {
                             chooseFiles(e.target.files && e.target.files.length ? e.target.files : null, 'meetingPhotos')
                           }} />
                  </InputBox>
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    placeholder='Enter Details..'
                  />
                </Modal>
              ) : null
            }
            {
              cancelModal.visible ? (
                <Modal
                  title='Enter Reason'
                  visible={cancelModal.visible}
                  onOk={() => {
                    updateMeeting(cancelModal.meetingId, 'Cancelled')
                  }}
                  onCancel={() => {
                    setCancelModal({ ...cancelModal, visible: false })
                  }}
                >
                  <TextArea
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder='Enter reason for Cancel'
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </Modal>
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default AgentMeetingList
