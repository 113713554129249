import TxnList from "./containers/reeudo/views/transactionList";
import EditTxnStudent from "./containers/reeudo/views/editTxnStudent";
import WrapExchangeComponent from "./containers/reeudo/views";
import UpdateForexMargin from "./containers/reeudo/views/updateAgentMargin";
import {adminRightUser} from "./components/_utils/appUtils";
import ForexStatusOffDates from "./containers/reeudo/views/forexStatus";
import UpdateExchangeMargin from "./containers/reeudo/views/updateExchangeMargin";

let menu = [
    {
        path: '/forex-exchange',
        title: 'Forex Exchange',
        name: 'Forex Exchange',
        icon: 'dashboard',
        key: 'exchangeComponent',
        dontShowOnMenu: false,
        component: WrapExchangeComponent,
        authority: [...adminRightUser, , 'agent', 'reeudoManager'],
        newLink: true,
        // restrict: true,
    },
    {
        path: '/forex-transactions',
        title: 'Forex Transactions',
        name: 'Forex Transactions',
        icon: 'dashboard',
        key: 'txnList',
        component: TxnList,
        authority: [...adminRightUser, 'agent', 'reeudoManager'],
        // restrict: true,
        newLink: true,
    },
    {
        path: '/editTxnStudent',
        title: 'Edit Student',
        name: 'Edit Student',
        icon: 'dashboard',
        key: 'editTxnStudent',
        component: EditTxnStudent,
        dontShowOnMenu: true,
        authority: [...adminRightUser, 'agent', 'reeudoManager'],
        // restrict: true,
    },
    {
        path: '/update-forex-margin',
        title: 'Update Forex Margin',
        name: 'Update Forex Margin',
        key: 'updateForexMargin',
        dontShowOnMenu: true,
        component: UpdateForexMargin,
        authority: [...adminRightUser, 'agent'],
        // restrict: true,
    },
    {
        path: '/update-exchange-margin',
        title: 'Update Exchange Margin',
        name: 'Update Exchange Margin',
        key: 'updateExchangeMargin',
        dontShowOnMenu: true,
        component: UpdateExchangeMargin,
        authority: [...adminRightUser, 'admin'],
        // restrict: true,
    },
    {
        path: '/forex-status',
        title: 'Forex Status',
        name: 'Forex Status',
        icon: 'dashboard',
        key: 'forexStatus',
        // dontShowOnMenu: true,
        component: ForexStatusOffDates,
        authority: [...adminRightUser,'reeudoManager'],
        restrict: true
    },
]

export default menu
