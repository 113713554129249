import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    createTransactionFxn,
    getFinalChangesInInrFxn,
    reeudoRatesFxn,
    singleAgentMarginFxn,
    singleQuotationFxn,
    updateTransactionFxn
} from "../actions";
import {Button, Checkbox, Col, Form, Icon, Input, Modal, notification, Row} from "antd";
import UnizLogo from "../../../assets/Unizportal-Logo.png";
import {displayDate} from "../../../components/_utils/appUtils";
import _ from "lodash"
import {stringToFixed} from "../reeudoUtils";

const UpdatedQuotationModal = (props) => {
    const {
        onClose,
        visible,
        onSkip,
        form: {getFieldDecorator, setFieldsValue},
        isForexOff,
        quotation,
        exchangeMargin,
        agentMargin
    } = props;
    let [updatedAmount, setUpdatedAmount] = useState(0)
    let [quotationData, setQuotationData] = useState(null);
    let [isCounselorBonusChecked, setIsCounselorBonusChecked] = useState(false);
    let [counselorName, setCounselorName] = useState('');
    let [counselorDesignation, setCounselorDesignation] = useState('');
    const [rateDirection, setRateDirection] = useState(null);
    // const [agentMargin, setAgentMargin] = useState(0.00);
    const [agentId, setAgentId] = useState(null);
    let [changeValue, setChangeValue] = useState(0)
    let [customerRate, setCustomerRate] = useState(0)
    let [finalChangesState, setFinalChangesState] = useState({})
    let dispatch = useDispatch();

    console.log(quotation, "quuuu")
    const events = {
        loadRates: async () => {
            let {data, success} = await dispatch(reeudoRatesFxn());
            if (success) {
                let {currencyData} = data;
                let findCurr = _.find(currencyData, (item) => {
                    return item.currencyCode == quotation.fromCurrency
                })
                if (findCurr) {
                    let uAmt = (quotation.yourRate + quotation.exchangeMargin) - (findCurr.yourRate + exchangeMargin);
                    if (uAmt) {
                        uAmt = parseFloat(uAmt.toFixed(2))
                    }
                    setCustomerRate((quotation.fxRate) - (uAmt))
                    setUpdatedAmount(uAmt);
                }
            }
        },
        getFinalChangesFxn: async () => {
            let obj = {
                currency: quotation.fromCurrency,
                amount: quotation.amount,
                customerRate: parseFloat(customerRate.toFixed(2)),
                sourceId: quotation.sourceId,
            }
            console.log(obj, '============');
            if (obj.currency && obj.amount && obj.customerRate && obj.sourceId) {
                let {data, success} = await dispatch(getFinalChangesInInrFxn(obj))
                console.log(data.charges);
                if (success && data && data.charges) {
                    setFinalChangesState(data.charges)
                }
            }
        },
    };

    useEffect(() => {
        if (customerRate) {
            events.getFinalChangesFxn()
        }
    }, [customerRate])


    useEffect(() => {
        events.loadRates()
    }, [])


    const forexOffModal = () => {
        Modal.warning({
            title: 'Forex is closed',
            content: (
                <div>
                    <p>Forex is Closed, Please try again between 8 a.m. and 4 p.m.</p>
                </div>
            ),
            onOk() {
            },
        });
    }
    const handleSubmit = async e => {
        e.preventDefault();
        if (isForexOff) {
            forexOffModal();
        } else {
            const {form} = props;
            form.validateFields(async (err, valData) => {
                if (!err) {
                    const transactionData = {
                        ...valData,
                        name: quotation.name,
                        emailAddress: quotation.emailId,
                        phoneNumber: quotation.phoneNo,
                        fxRate: finalChangesState.fxRate,
                        ibrRate: finalChangesState.ibrRate,
                        yourRate: finalChangesState.yourRate,
                        amount: quotation.amount,
                        currency: quotation.fromCurrency,
                        sourceId: quotation.sourceId,
                        agentId: agentId,
                        counselorBonusApplied: isCounselorBonusChecked,
                        exchangeMargin: exchangeMargin || 0.00,
                        agentMargin: agentMargin,
                        quotationId: quotation.quotationId
                    };

                    if (isCounselorBonusChecked) {
                        transactionData.counselorName = counselorName;
                        transactionData.counselorDesignation = counselorDesignation;
                    }
                    console.log(transactionData, "transaction data")
                    let resp = await dispatch(updateTransactionFxn(transactionData));

                    if (resp.success && resp.data) {
                        notification.success({
                            message: resp.message
                        });
                        form.resetFields();
                        onClose();
                    } else {
                        notification.error({
                            message: resp.message
                        });
                    }
                } else {
                    notification.warning({
                        message: 'Fill All Required Fields'
                    });
                }
            });
        }
    };


    const handleCheckboxChange = (e) => {
        setIsCounselorBonusChecked(e.target.checked);
    };

    return (
        <Modal
            visible={visible}
            title={"Book Transaction Details"}
            onCancel={onClose}
            width={"45%"}
            footer={null}
        >
            {quotation && quotation._id && (
                <>
                    <div className="unizportal">
                        <div className={'jcsb'}>
                            <div>
                                <img src={UnizLogo} alt={'logo'} style={{height: 50}}/>
                            </div>
                            <div>
                                <div>Date : <b>{displayDate(quotation.createdAt)}</b></div>
                                <div>Quotation Id : <b>#{quotation.quotationId}</b></div>
                            </div>
                        </div>
                        <div className="rate-change-display mt10">
                            <div className={`rate-change ${rateDirection}`}>
                                <label
                                    className={`rate-change-text ${updatedAmount < 0 ? 'label label-success-green' : 'label label-danger'}`}>
                                    <Icon type={updatedAmount < 0 ? 'arrow-up' : 'arrow-down'}/>&nbsp;
                                    {updatedAmount < 0 ? 'Rate Increased' : 'Rate Decreased'} by {-updatedAmount} INR
                                </label>
                            </div>
                        </div>
                        <div className={'description-section mt10'}>

                            <table className="table table-bordered">
                                <tr>
                                    <th colSpan={2}>Description</th>
                                </tr>
                                <tbody>
                                <tr>
                                    <td>Currency Type</td>
                                    <td>{quotation.fromCurrency}</td>
                                </tr>
                                <tr>
                                    <td>Foreign Currency Amount</td>
                                    <td>{quotation.amount}</td>
                                </tr>
                                <tr>
                                    <td>Booking Rate</td>
                                    <td>{finalChangesState.fxRate} INR</td>
                                </tr>
                                <tr>
                                    <td>Your Rate</td>
                                    <td>{stringToFixed(finalChangesState.yourRate + exchangeMargin)} INR</td>
                                </tr>
                                <tr>
                                    <td>Gross Amount</td>
                                    <td>{stringToFixed(finalChangesState.base)} INR</td>
                                </tr>
                                <tr>
                                    <td>GST (Foreign Conversion)</td>
                                    <td>{stringToFixed(finalChangesState.gst)} INR</td>
                                </tr>
                                <tr>
                                    <td>Bank Processing Fee</td>
                                    <td>{stringToFixed(finalChangesState.bank)} INR</td>
                                </tr>
                                <tr>
                                    <td>Nostro Charges</td>
                                    <td>{stringToFixed(finalChangesState.nostro)} INR</td>
                                </tr>
                                <tr>
                                    <td>TCS Charges</td>
                                    <td>{stringToFixed(finalChangesState.tcs)} INR</td>
                                </tr>
                                <tr>
                                    <td>Total Amount</td>
                                    <td className="total">{stringToFixed(finalChangesState.total)} INR</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <hr className={'hr1'}/>
                    <div className=" row">
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Contact Details</b></div>
                            <div>{quotation.agentId.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotation.agentId.mobile}
                                </div>
                                {quotation.agentId.mobile && quotation.agentId.email ?
                                    <div>&nbsp; | &nbsp;</div> : null}
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotation.agentId.email}
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <div className={'font14'}><b>Student Details</b></div>
                            <div>{quotation.name}</div>
                            <div className={'aic'}>
                                <div className={'aic'}><Icon type={'mobile'}/> &nbsp; {quotation.phoneNo} </div>
                                &nbsp; | &nbsp;
                                <div className={'aic'}><Icon type={'mail'}/> &nbsp;{quotation.emailId}</div>
                            </div>
                        </div>

                    </div>
                    <hr className={'hr1'}/>

                    <Checkbox onChange={handleCheckboxChange}>
                        Do you want to add counsellor bonus?
                    </Checkbox>
                    {isCounselorBonusChecked && (
                        <Form layout="vertical" className={'mt20'}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Counselor Name">
                                        {getFieldDecorator('counselorName', {
                                            rules: [
                                                {
                                                    required: isCounselorBonusChecked,
                                                    message: 'Please enter counselor name',
                                                },
                                            ],
                                        })(
                                            <Input
                                                value={counselorName}
                                                className={'form-control'}
                                                onChange={(e) => setCounselorName(e.target.value)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Counselor Designation">
                                        {getFieldDecorator('counselorDesignation', {
                                            rules: [
                                                {
                                                    required: isCounselorBonusChecked,
                                                    message: 'Please enter counselor designation',
                                                },
                                            ],
                                        })(
                                            <Input
                                                value={counselorDesignation}
                                                className={'form-control'}
                                                onChange={(e) => setCounselorDesignation(e.target.value)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    <div>
                        <Button htmlType="submit" className={"roundBtn"} onClick={handleSubmit}>
                            Confirm & Book Transaction
                        </Button> &nbsp;
                        <Button className={"roundBtn"} onClick={onSkip}>
                            Skip
                        </Button>
                    </div>
                </>
            )}

        </Modal>
    );
}
export default Form.create()(UpdatedQuotationModal)

