import React, {useState, useEffect, useRef} from 'react';
import PageHeaderWrapper from '../../../components/PageHeaderWrapper';
import {
    Button,
    Card, Col, DatePicker,
    Row, Tooltip, notification,
    Modal, Input
} from 'antd';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import {TableComp} from 'sz-react-utils-lite';
import {useHistory} from 'react-router-dom';
import {
    dateFilterRange,
    DefaultTablePagination,
    InputBox,
    newFormatDisplayDate
} from "../../../components/_utils/appUtils";
import moment from "moment";
import {
    allTransactionFxn,
    currentForexStatusFxn,
    deleteForexTransactionFxn,
    sendOtpFxn,
    verifyOtpAndDeleteFxn,
    transferAssignUserFxn
} from "../actions";
import AddBasicDetails from './agentDocs'
import {ColumnWidth} from "../../WebComponent/columnWidth";
import MobileEmailInfo from "../../../components/commonComponents/mobileEmailInfo";
import AgentTransactionModal from "./transactionModal";
import TransferAgentModal from "./transferAgentModal";

const {TextArea} = Input
const {RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const initFilters = {
    startDate: null,
    endDate: null,
};
let reeudoMaster = ['admin', 'reeudoManager', 'itTeam']
const TxnList = (props) => {
    let {user} = props;
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState(initFilters);
    const [isModalVisible, setModalVisible] = useState({
        visible: false,
        appId: '',
        id: ''
    });
    const [isAgentModal, setIsAgentModal] = useState({
        visible: false,
    });
    const [isTransferModalVisible, setTransferModalVisible] = useState({
        visible: false,
        transactionId: ''
    });
    const [isForexOff, setIsForexOff] = useState(false);
    const [loading, setLoading] = useState(true)
    const [isDeleteModalVisible, setDeleteModalVisible] = useState({
        visible: false,
        transactionId: '',
        reason: ''
    });
    const [isOtpModalVisible, setOtpModalVisible] = useState({
        visible: false,
        transactionId: '',
        otp: ''
    });


    const tableRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentForexStatus = async () => {
        try {
            setLoading(true);
            const resp = await dispatch(currentForexStatusFxn());
            if (resp.status === 'off') {
                setIsForexOff(true);
            } else {
                setIsForexOff(false);
            }
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        currentForexStatus();
    }, []);
    const showModal = (appId, id) => {
        setModalVisible({
            visible: true,
            appId: appId,
            id: id
        });
    };

    const closeModal = () => {
        setModalVisible({
            visible: false,
            appId: '',
            id: ''
        });
    };

    const showTransferModal = (transactionId) => {
        setTransferModalVisible({
            visible: true,
            transactionId: transactionId
        });
    };

    const closeTransferModal = () => {
        setTransferModalVisible({
            visible: false,
            transactionId: ''
        });
        tableRef.current.reload()
    };

    const showAgentModal = () => {
        setIsAgentModal({
            visible: true,
        });
    };

    const closeAgentModal = () => {
        setIsAgentModal({
            visible: false,
        });
    };
    const apiRequest = async (params) => {
        params.sortField = '_id';
        params.sortOrder = 'descend';

        if (filters.startDate) {
            params.startDate = filters.startDate;
        }
        if (filters.endDate) {
            params.endDate = filters.endDate;
        }

        let resp = await dispatch(allTransactionFxn(params));
        setTotal(resp.total);
        return resp;
    };

    const updateFilter = (data) => {
        setFilters((prevState) => ({
            ...prevState,
            ...data
        }));
    };

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.reload();
        }
    }, [filters.startDate, filters.endDate]);

    const FilterComponent = () => (
        <Row gutter={12} className="filter_box mt5">
            <Col span={6}>
                <div className="filter-box">
                    <RangePicker
                        format={dateFormat}
                        value={[
                            filters.startDate ? moment(filters.startDate, dateFormat) : null,
                            filters.endDate ? moment(filters.endDate, dateFormat) : null
                        ]}
                        onChange={(dates) => {
                            updateFilter({
                                startDate: dates && dates[0] ? dates[0].format(dateFormat) : null,
                                endDate: dates && dates[1] ? dates[1].format(dateFormat) : null,
                            });
                        }}
                        ranges={dateFilterRange}
                    />
                </div>
            </Col>
        </Row>
    );

    const getColumns = () => {
        let newCol = _.filter(columns, (item) => {
            return !item.hidden;
        });
        return newCol;
    };




    const handleEdit = (appId) => {
        history.push(`/editTxnStudent?appId=${appId}`);
    };


    const showDeleteModal = (transactionId) => {
        setDeleteModalVisible({
            visible: true,
            transactionId,
            reason: ''
        });
    };

    const closeDeleteModal = () => {
        setDeleteModalVisible({
            visible: false,
            transactionId: '',
            reason: ''
        });
    };

    const handleDeleteSubmit = async () => {
        const {transactionId, reason} = isDeleteModalVisible;

        if (reason.trim()) {
            try {
                // Send OTP to user
                const otpResp = await dispatch(sendOtpFxn({transactionId, reason}));
                if (otpResp.success) {
                    notification.success({message: otpResp.message});
                    // Show OTP modal after sending OTP
                    setOtpModalVisible({
                        visible: true,
                        transactionId: transactionId,
                        otp: ''
                    });
                    closeDeleteModal();
                } else {
                    notification.error({message: otpResp.message});
                }
            } catch (error) {
                notification.error({message: 'Error sending OTP'});
            }
        } else {
            notification.error({message: 'Please provide a reason for deletion'});
        }
    };

    const handleOtpSubmit = async () => {
        const {transactionId, otp} = isOtpModalVisible;

        if (otp.trim()) {
            try {
                const verifyResp = await dispatch(verifyOtpAndDeleteFxn({transactionId, otp}));
                if (verifyResp.success) {
                    notification.success({message: verifyResp.message});
                    tableRef.current.reload();
                    closeOtpModal();
                } else {
                    notification.error({message: verifyResp.message});
                }
            } catch (error) {
                notification.error({message: 'Error verifying OTP'});
            }
        } else {
            notification.error({message: 'Please enter the OTP'});
        }
    };


    const closeOtpModal = () => {
        setOtpModalVisible({
            visible: false,
            transactionId: '',
            otp: ''
        });
    };

    const handleReasonChange = (e) => {
        setDeleteModalVisible((prevState) => ({
            ...prevState,
            reason: e.target.value
        }));
    };

    const columns = [
        {
            title: 'Sr No.',
            dataIndex: 'serialNo',
            width: 80,
            render: (val, record, index) => <div>{index + 1}</div>
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 100,
            render: (item) => <div>{newFormatDisplayDate(item)}</div>
        },
        {
            title: 'Student Name',
            dataIndex: 'name',
            key: 'name',
            width: 120,
        },
        {
            title: 'Phone No',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: 100,

        },
        // {
        //     title: 'Status',
        //     dataIndex: 'status',
        //     key: 'status',
        // },
        {
            title: 'Agent',
            key: 'name',
            dataIndex: 'agentId',
            width: 210,
            hidden: !reeudoMaster.includes(user.userType),
            render: (item) => {
                return (
                    item ? <ColumnWidth width={150}>
                        {item && item.name ? item.name : ""}<br/>
                        ({item.companyName})
                    </ColumnWidth> : null
                )
            }
        },
        {
            title: 'Email / Mobile',
            dataIndex: 'email',
            key: 'email',
            width: 210,
            hidden: !reeudoMaster.includes(user.userType),
            render: (item, record) => {
                let {agentId} = record;
                return (
                    agentId ? <div>
                        <MobileEmailInfo data={agentId} type={'email'}
                                         value={agentId && agentId.email ? agentId.email : ""}/>
                    </div> : null
                )
            }
        },
       // {
       //      title: 'Mobile No',
       //      dataIndex: 'mobile',
       //
       //      key: 'mobile',
       //      hidden: !reeudoMaster.includes(user.userType),
       //      render: (item, record) => {
       //          let {agentId} = record;
       //          return (
       //              agentId ? <div>
       //                  <MobileEmailInfo data={agentId} type={'email'}
       //                                   value={agentId && agentId.mobile ? agentId.mobile : ""}/>
       //              < /div> : null
       //          )
       //      }
       //  },
        {
            title: 'Your Rate',
            dataIndex: 'yourRate',
            width: 100,
            render: (text, record) => <span>{(parseFloat(record.exchangeMargin) + text).toFixed(2)}</span>,
        },

        {
            title: 'Booking Rate',
            dataIndex: 'fxRate',
            width: 100,
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,

        },
        {
            title: 'Txn Id',
            dataIndex: 'rappId',
            key: 'rappId',
            width: 120,
        },
        {
            title: 'Assigned User',
            key: 'assignedUserId',
            dataIndex: 'assignedUserId',
            width: 220,
            render: (item) => {
                return (
                    <div>
                        {item && item.name ? <div>{item.name}</div> : ""}
                        {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ""}
                        {item && item.email ? <div><b>Email-</b>{item.email}</div> : ""}
                    </div>
                );
            }
        },
        {
            title: 'Uniz Profit',
            dataIndex: 'unizProfit',
            key: 'unizProfit',
            width: 100,
            hidden: user && (user.userType == 'agent' || user.userType == 'subAgent'),

        },
        // {
        //     title: 'Nostro Profit',
        //     dataIndex: 'nostroProfit',
        //     key: 'nostroProfit',
        // },
        {
            title: 'Agent Profit',
            dataIndex: 'agentProfit',
            width: 100,
            key: 'agentProfit',
        },
        {
            title: 'Total Profit',
            dataIndex: 'totalProfit',
            key: 'totalProfit',
            width: 100,
            hidden: user && (user.userType == 'agent' || user.userType == 'subAgent'),
        },
        // {
        //     title: 'Referenced By',
        //     key: 'referenceBy',
        //     dataIndex: 'referenceBy',
        //     render: (item) => {
        //         return (
        //             <div style={{width: 260}}>
        //                 {item && item.name ? <div>{item.name}</div> : ""}
        //                 {item && item.mobile ? <div><b>Mobile-</b>{item.mobile}</div> : ""}
        //                 {item && item.email ? <div><b>Email-</b>{item.email}</div> : ""}
        //             </div>
        //         );
        //     }
        // },
        {
            key: 'actions',
            title: 'Actions',
            fixed: 'right',
            width: 80,
            render: (text, record) => {
                return (
                    record.isHiddenForAgent !== true ? <React.Fragment>
                        <div>
                            {user.userType !== 'agent' && (
                                <>
                                    <Tooltip title="Edit">
                                        <Button
                                            shape="circle"
                                            style={{marginRight: 6}}
                                            size="small"
                                            onClick={() => handleEdit(record.rappId)}
                                            icon="edit"
                                        />
                                    </Tooltip>
                                    <Tooltip title="Transfer Agent">
                                        <Button
                                            shape="circle"
                                            size="small"
                                            onClick={() => showTransferModal(record._id)}
                                            icon="swap"
                                            style={{marginRight: 6}}
                                        />
                                    </Tooltip>
                                    {/*<Tooltip title="Transfer User">*/}
                                    {/*    <Button*/}
                                    {/*        shape="circle"*/}
                                    {/*        size="small"*/}
                                    {/*        onClick={() => showTransferModal(record._id)}*/}
                                    {/*        icon="swap"*/}
                                    {/*        style={{marginRight: 6}}*/}
                                    {/*    />*/}
                                    {/*</Tooltip>*/}
                                </>
                            )}

                            {user.userType == 'agent' && (
                                <Tooltip title="Update">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        onClick={() => showModal(record.rappId, record._id)}
                                        icon="sync"
                                    />
                                </Tooltip>
                            )}
                            {
                                user.userType === 'reeudoManager' && (
                                    <Tooltip title="Delete">
                                        <Button
                                            shape="circle"
                                            size="small"
                                            onClick={() => showDeleteModal(record._id)}
                                            icon="delete"
                                            style={{marginRight: 6}}
                                        />
                                    </Tooltip>
                                )
                            }
                        </div>
                    </React.Fragment> : null
                );
            }
        }
    ];



    return (
        <>
            <div className='row mt-4'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='table-head d-flex align-items-center all-student-search'>
                            <h5>All Transactions (Total : {total || 0})</h5>

                        </div>
                        <div className='card-body table-responsive'>
                            <FilterComponent/>

                            {/*     {!isForexOff && !loading && (
                                <TableComp
                                    columns={getColumns()}
                                    apiRequest={apiRequest}
                                    ref={tableRef}
                                    pagination={{
                                        position: 'top',
                                        pageSizeOptions: ['10', '20', '25', '50'],
                                        defaultPageSize: 10
                                    }}
                                />
                            )}*/}

                            <TableComp
                                columns={getColumns()}
                                apiRequest={apiRequest}
                                ref={tableRef}
                                extraProps={{scroll: {x: 600}}}
                                pagination={{
                                    position: 'top',
                                    pageSizeOptions: ['10', '20', '25', '50'],
                                    defaultPageSize: 10
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                isModalVisible.visible ? (
                    <AddBasicDetails
                        visible={isModalVisible.visible}
                        onClose={closeModal}
                        appId={isModalVisible.appId}
                        transactionId={isModalVisible.id}
                    />
                ) : null
            }
            {
                isTransferModalVisible.visible ? (
                    <TransferAgentModal
                        visible={isTransferModalVisible.visible}
                        onClose={closeTransferModal}
                        transactionId={isTransferModalVisible.transactionId}
                    />
                ) : null
            }

            {
                isAgentModal.visible ? (
                    <AgentTransactionModal
                        visible={isAgentModal.visible}
                        onClose={closeAgentModal}

                    />
                ) : null
            }
            {isDeleteModalVisible.visible && (
                <Modal
                    title="Delete Transaction ?"
                    visible={isDeleteModalVisible.visible}
                    onCancel={closeDeleteModal}
                    okText={"Delete Now"}
                    onOk={handleDeleteSubmit}>
                    <InputBox title={'Enter Reason'}>
                        <TextArea
                            className={'form-control'}
                            style={{height: 100}}
                            value={isDeleteModalVisible.reason}
                            onChange={handleReasonChange}
                            placeholder="Enter reason for deletion"
                        />
                    </InputBox>
                </Modal>
            )}

            {isOtpModalVisible.visible && (
                <Modal
                    title="Enter OTP"
                    visible={isOtpModalVisible.visible}
                    onCancel={closeOtpModal}
                    onOk={handleOtpSubmit}
                    okText="Verify"
                >
                    <Input
                        type="text"
                        className={'form-control'}
                        value={isOtpModalVisible.otp}
                        onChange={(e) => setOtpModalVisible({...isOtpModalVisible, otp: e.target.value})}
                        placeholder="Enter OTP"
                    />
                </Modal>
            )}
        </>


    );
};

export default TxnList;
