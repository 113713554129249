import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, notification } from 'antd';
import { useDispatch } from 'react-redux';
import { listAllUsers } from "../../users/actions/user";
import { transferAgentFxn, getAgentsFxn } from "../actions";

const { Option } = Select;

const TransferAgentModal = (props) => {
    let { visible, onClose, transactionId } = props;
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [agents, setAgents] = useState([]);
    const dispatch = useDispatch();

    let getAgentList = async () => {
        let params = {
            results: 100,
            count: 100
        }
        let resp = await dispatch(getAgentsFxn(params));
        setAgents(resp);
    }

    useEffect(() => {
        getAgentList();
    }, []);

    const handleSubmit = async () => {
        console.log(selectedAgent, "selectt agent")
        if (selectedAgent) {
            let resp = await dispatch(transferAgentFxn(transactionId, {agentId: selectedAgent}));
            console.log(resp, "Transfer response");
            if (resp.success) {
                notification.success({message: resp.message})
                onClose();
            } else {
                notification.error({message: resp.message})
            }
        } else {

        }
    }

    return (
        <Modal
            title="Transfer Transaction"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Select
                placeholder="Select an agent"
                onChange={setSelectedAgent}
                style={{ width: '100%', marginBottom: 16 }}
            >
                {agents && agents.map(agent => (
                    <Option key={agent._id} value={agent._id}>
                        {agent.name}
                    </Option>
                ))}
            </Select>
            <Button type="primary" onClick={handleSubmit}>
                Submit
            </Button>
        </Modal>
    );
};

export default TransferAgentModal;
