import React, {useState, useRef, useEffect} from 'react';
import {
    Collapse, Table, Card, Button, Row, Col, Form, Input, DatePicker, Upload, Icon, notification, Select, Radio,
    Modal, Tooltip
} from 'antd';
import {useDispatch} from 'react-redux';
import {InputBox} from "../../../components/_utils/appUtils";
import {
    designatedCountryTypes,
    addressProofTypes,
    SourceIncomeTypes,
    SelfBusinessTypes,
    SelfProfessionalTypes,
    SourceFundsTypes,
    OccupationTypes,
    SalariedTypes,
    PaymentMethodTypes,
    GenderTypes,
    SaluationTypes,
    statusTypes
} from "../reeudoUtils";
import ArtBoard from "../../../assets/Artboard–20.png";
import PassFront from "../../../assets/Passport-Front1.png";
import PassBack from "../../../assets/PassportBacka.png";
import Pancard from "../../../assets/Pan-card-icon.png"
import OtherDoc from "../../../assets/otherdoc.png";
import moment from 'moment'
import {
    getMetaInfoFxn, updateStudentFxn,
    updateStudentDocFxn, updateSenderDocFxn,
    downloadSenderDocFxn,
    createSenderFxn, getDocumentByIdFxn,
    downloadStudentDocFxn, createPaymentFxn,
    paymentInstructionFxn, createBeneficiaryFxn, getFinalChangesInInrFxn,
    getFinalPaymentChangesFxn, getA2FormFxn, uploadA2FormFxn, updateSenderFxn, getSenderDocsFxn
} from "../actions";
import UpdateKycStatus from "./updateKycStatus";
import {stringToFixed} from "../reeudoUtils";

const {Option} = Select;

const EditTxnStudent = (props) => {
    const {form: {setFieldsValue, getFieldDecorator, getFieldValue},} = props
    const [activeKey, setActiveKey] = useState(['1']);
    const tableRef = useRef();
    const dispatch = useDispatch();
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [studentData, setStudentData] = useState({});
    const [senderData, setSenderData] = useState({});
    const [beneficiaryData, setBeneficiaryData] = useState({});
    const {Panel} = Collapse;
    const [selectedAddressProof, setSelectedAddressProof] = useState('passport');
    const [quotationId, setQuotationId] = useState(null)
    const [documentsData, setDocumentsData] = useState([]);
    const [chargeComponent, setChargeComponent] = useState({})
    const [occupationValOptions, setOccupationValOptions] = useState([]);
    const [amountData, setAmountData] = useState(null)
    const [isModalVisible, setModalVisible] = useState(false);
    const [kycStatus, setKycStatus] = useState('');
    const [txnStatus, setTxnStatus] = useState('');
    const [a2FormStatus, setA2FormStatus] = useState('');
    const [kycStatusConsultant, setKycStatusConsultant] = useState('');
    const [bankCodeLabel, setBankCodeLabel] = useState('Bank Code');
    const [bankCodePlaceholder, setBankCodePlaceholder] = useState('Bank Code');
    const [selectedSenderDocuments, setSelectedSenderDocuments] = useState([]);
    const [senderDocumentData, setSenderDocumentData] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [isChargeModal, setIsChargeModal] = useState(false);

    const searchParams = new URLSearchParams(window.location.search);

    const appId = searchParams.get('appId');
    const showModal = () => {
        // console.log('trueeeeeeeeeeee')
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        window.location.reload()
    };

    const showChargeModal = () => {
        setIsChargeModal(true);
    };

    const handleCancel = () => {
        setIsChargeModal(false);
    };


    const apiRequest = async () => {
        let resp = await dispatch(getMetaInfoFxn({RappId: appId}));
        if (resp && resp.metaData && resp.metaData.UNIVERSITY) {
            setStudentData(resp.metaData.UNIVERSITY);
        }
        if (resp && resp.metaData && resp.metaData.PAYER) {
            const accountInfoList = resp.metaData.PAYER.accountInfoList
            setSenderData(accountInfoList[0]);

            // console.log(accountInfoList[0], "sendnnderrdd")
            setFieldsValue({
                idNumber: accountInfoList[0].idNumber,
                accAddressProofNo: accountInfoList[0].accAddressProofNo,
                accAddressProofExpiryDate: accountInfoList[0].accAddressProofExpiryDate,
                accCustomerId: accountInfoList[0].accCustomerId,
                accSalutation: accountInfoList[0].accSalutation,
                name: accountInfoList[0].name,
                accEmailId: accountInfoList[0].accEmailId,
                accMobile: accountInfoList[0].accMobile,
                accDob: accountInfoList[0].accDob,
                accGender: accountInfoList[0].accGender,
                accCountryOfBirth: accountInfoList[0].accCountryOfBirth,
                accPlaceOfBirth: accountInfoList[0].accPlaceOfBirth,
                accNationality: accountInfoList[0].accNationality,
                accAddress: accountInfoList[0].accAddress,
                accPinCode: accountInfoList[0].accPinCode,
                accState: accountInfoList[0].accState,
                city: accountInfoList[0].city,
                accOccupationType: accountInfoList[0].accOccupationType,
                accOccupationVal: accountInfoList[0].accOccupationVal,
                source: accountInfoList[0].source,
                purpose: accountInfoList[0].purpose,
                accSourceOfIncome: accountInfoList[0].accSourceOfIncome,
                accGrossIncome: accountInfoList[0].accGrossIncome,
                accPEP: accountInfoList[0].accPEP,
                accForeignCharges: accountInfoList[0].accForeignCharges,
                bankCode: accountInfoList[0].bankCode,
                accountNo: accountInfoList[0].accountNo,
                bankName: accountInfoList[0].bankName,
                state: accountInfoList[0].state,
                bankCity: accountInfoList[0].city,
                bankAddress: accountInfoList[0].bankAddress,
                accountRef: accountInfoList[0].accountRef
            });
        }
        if (resp && resp.metaData && resp.metaData.PAYEE) {
            const beneficiary = resp.metaData.PAYEE.accountInfoList
            setBeneficiaryData(beneficiary[0]);
            setFieldsValue({
                accAddress: beneficiary.accAddress,
                accCountry: beneficiary.accCountry,
                accIdentificationNo: beneficiary.accIdentificationNo,
                accIdentificationNo2: beneficiary.accIdentificationNo2,
                accIdentificationType: beneficiary.accIdentificationType,
                accountNo: beneficiary.accountNo,
                accountRef: beneficiary.accountRef,
                amount: beneficiary.amount,
                // bankAddress: beneficiary.bankAddress,
                bankCode: beneficiary.bankCode,
                // bankName: beneficiary.bankName,
                country: beneficiary.country,
                // idNumber: beneficiary.idNumber,
                // name: beneficiary.name,

            });
        }

        if (resp && resp.metaData && resp.metaData.START) {
            const {fromCurrency, amount, exchangeRate, ibrRate, quotationId} = resp.metaData.START;
            setQuotationId(quotationId)
            setAmountData(amount)
            const finalChangesObj = {
                currency: fromCurrency,
                amount: amount,
                fxRate: exchangeRate,
                ibrRate: ibrRate,
                appId: appId
            };
            // console.log(finalChangesObj, "objjjjj")
            // getFinalChangesFxn(finalChangesObj);
        }
        if (resp) {
            const {kycStatus, txnStatus, a2FormStatus, kycStatusConsultant} = resp;

            setKycStatus(kycStatus);
            setTxnStatus(txnStatus);
            setA2FormStatus(a2FormStatus);
            setKycStatusConsultant(kycStatusConsultant);
        }
        if (resp && resp.metaData && resp.metaData.CONFIRMED) {
            const chargeComponent = resp.metaData.CONFIRMED.chargeComponent;
            setChargeComponent(chargeComponent);

        }

    }

    // const getFinalChangesFxn = async (obj) => {
    //     if (obj) {
    //         let { data, success } = await dispatch(getFinalPaymentChangesFxn(obj));
    //         // console.log(data, "Final Charges Data");
    //
    //         if (data && data.charges) {
    //             setChargeComponent(data.charges);
    //         }
    //     }
    // };

    const getDocById = async () => {
        let resp = await dispatch(getDocumentByIdFxn({applicationId: appId}));
        // console.log(resp, "resppp222s")
        setDocumentsData(resp);
    }
    useEffect(() => {
        apiRequest();
        getDocById();
    }, []);

    useEffect(() => {
        if (senderData.accountRef) {
            getSenderDocs(senderData.accountRef);
        }
    }, []);

    const handleAddressProofChange = (value) => {
        setSelectedAddressProof(value);
    };

    const confirmPayment = async (chargeComponent) => {
        let resp = await dispatch(createPaymentFxn(appId, {chargeComponent: chargeComponent}))
        if (resp.success) {
            notification.success({message: resp.message})
        } else {
            notification.error({message: resp.message})
        }
    }
    const getDynamicFields = () => {
        switch (selectedAddressProof) {
            case 'aadhaarCard':
                return {
                    idNumberLabel: 'Aadhaar Number',
                    idNumberPlaceholder: 'Enter Aadhaar Number',
                    expiryDateDisabled: true,
                };
            case 'voterId':
                return {
                    idNumberLabel: 'Voter ID Number',
                    idNumberPlaceholder: 'Enter Voter ID Number',
                    expiryDateDisabled: true,
                };
            case 'passport':
                return {
                    idNumberLabel: 'Passport Number',
                    idNumberPlaceholder: 'Enter Passport Number',
                    expiryDateDisabled: false,
                };
            case 'drivingLicense':
                return {
                    idNumberLabel: 'Driving License Number',
                    idNumberPlaceholder: 'Enter Driving License Number',
                    expiryDateDisabled: false,
                };
            default:
                return {
                    idNumberLabel: 'ID Number',
                    idNumberPlaceholder: 'Enter ID Number',
                    expiryDateDisabled: false,
                };
        }
    };

    const {idNumberLabel, idNumberPlaceholder, expiryDateDisabled} = getDynamicFields();
    const inputTypes = {
        fields: [
            {
                key: "studentName",
                label: "Name",
                type: 'text',
                required: true,
                placeholder: "Name",
            },
            {
                key: "passportNumber",
                label: "Passport",
                type: 'text',
                required: true,
                placeholder: "Passport",
            },
            {
                key: 'country',
                label: 'Designated Country',
                placeholder: 'Designated Country',
                required: true,
                type: 'select',
                options: designatedCountryTypes,
                keyAccessor: 'value',
                valueAccessor: 'name',
                onChange: x => {
                    setFieldsValue({
                        country: x
                    });
                }
            },
            {
                key: "courseDetails",
                label: "Course Details",
                required: true,
                type: 'text',
                placeholder: "Course Details",
            },
            {
                key: "studentUniversityId",
                label: "Student ID",
                type: 'text',
                // required: true,
                placeholder: "Student ID",
            },
            {
                key: "otherUniversityName",
                label: "Other University Name",
                type: 'text',
                required: true,
                placeholder: "Other University Name",
            },
        ],
    };

    const senderInput = {
        fields: [
            {
                key: "idNumber",
                label: "Pan Number",
                type: 'text',
                required: true,
                placeholder: "Pan Number",
            },
            {
                key: "accAddressProofNo",
                label: idNumberLabel,
                required: true,
                type: 'text',
                placeholder: idNumberPlaceholder,
            },
            {
                key: "accAddressProofExpiryDate",
                label: "Expiry Date",
                type: 'date',
                placeholder: "Expiry Date",
                disabled: expiryDateDisabled,
            },
            {
                key: "accCustomerId",
                label: "Customer ID",
                type: 'text',
                // required: true,
                placeholder: "Customer ID",
            },
            {
                key: "accSalutation",
                label: "Salutation",
                // required: true,
                type: 'select',
                options: SaluationTypes,
                placeholder: "Salutation",
            },
            {
                key: "name",
                label: "Sender Name",
                required: true,
                type: 'text',
                placeholder: "Sender Name",
            },
            {
                key: "accEmailId",
                label: "Email ID",
                required: true,
                type: 'email',
                placeholder: "Email ID",
            },
            {
                key: "accMobile",
                label: "Mobile",
                required: true,
                type: 'text',
                placeholder: "Mobile",
            },
            {
                key: "accDob",
                label: "DOB",
                type: 'date',
                required: true,
                placeholder: "DOB",
            },
            {
                key: "accGender",
                label: "Gender",
                required: true,
                placeholder: "Gender",
                type: 'select',
                options: GenderTypes,
            },
            {
                key: "accCountryOfBirth",
                label: "Country of Birth",
                required: true,
                type: 'text',
                placeholder: "Country of Birth",
            },
            {
                key: "accPlaceOfBirth",
                label: "Place of Birth",
                required: true,
                type: 'text',
                placeholder: "Place of Birth",
            },
            {
                key: "accNationality",
                label: "Nationality",
                // required: true,
                type: 'text',
                placeholder: "Nationality",
            },
            {
                key: "accAddress",
                label: "Address",
                type: 'text',
                required: true,
                placeholder: "Address",
            },
            {
                key: "accPinCode",
                label: "Pincode",
                type: 'text',
                required: true,
                placeholder: "Pincode",
            },
            {
                key: "accState",
                label: "State",
                required: true,
                type: 'text',
                placeholder: "State",
            },
            {
                key: "city",
                label: "City",
                required: true,
                type: 'text',
                placeholder: "City",
            },
            {
                key: "accOccupationType",
                label: "Occupation",
                type: 'select',
                required: true,
                placeholder: "Occupation",
                options: OccupationTypes
            },
            {
                key: "accOccupationVal",
                label: "Occupation Value",
                type: 'select',
                required: true,
                placeholder: "Select Occupation Value",
                options: []
            },
            {
                key: "source",
                label: "Source of Fund",
                required: true,
                type: 'select',
                placeholder: "Source of Fund",
                options: SourceFundsTypes
            },
            {
                key: "purpose",
                label: "Purpose",
                required: true,
                type: 'text',
                placeholder: "Purpose",
            },
            {
                key: "accSourceOfIncome",
                label: "Source of Income",
                required: true,
                type: 'select',
                options: SourceIncomeTypes,
                placeholder: "Source of Income",
            },
            {
                key: "accPEP",
                label: "Are you PEP or related to one",
                required: true,
                type: "radio",
                options: [
                    {label: "Yes", value: "yes"},
                    {label: "No", value: "no"},
                ],
            },
            {
                key: "accForeignCharges",
                label: "Foreign Bank Charges",
                required: true,
                type: "radio",
                options: [
                    {label: "Self", value: "self"},
                    {label: "Beneficiary", value: "beneficiary"},
                    {label: "Shared", value: "shared"},
                ],
            },
            {
                key: "accGrossIncome",
                label: "Gross Annual Income",
                required: true,
                type: 'text',
                placeholder: "Gross Annual Income",
            },


            {
                key: "bankCode",
                label: "IFSC Code",
                required: true,
                type: 'text',
                placeholder: "IFSC Code",
            },
            {
                key: "accountNo",
                label: "Account Number",
                type: 'text',
                required: true,
                placeholder: "Account Number",
            },
            {
                key: "accountNo",
                label: "Confirm Account Number",
                required: true,
                type: 'text',
                placeholder: "Confirm Account Number",
            },
            {
                key: "bankName",
                label: "Bank Name",
                type: 'text',
                required: true,
                placeholder: "Bank Name",
            },
            {
                key: "state",
                label: "Bank State",
                required: true,
                type: 'text',
                placeholder: "Bank State",
            },
            {
                key: "city",
                label: "Bank City",
                required: true,
                type: 'text',
                placeholder: "Bank City",
            },
            {
                key: "bankAddress",
                label: "Bank Address",
                required: true,
                type: 'text',
                placeholder: "Bank Address",
            },
        ],
    };

    const beneficiaryInput = {
        fields: [
            {
                key: "transferVia",
                label: "Payment Method",
                required: true,
                type: 'select',
                placeholder: "Payment Method",
                options: PaymentMethodTypes,
                keyAccessor: 'value',
                valueAccessor: 'name',
                defaultValue: 'Other',
            },
            {
                key: "name",
                label: "Beneficiary Name",
                required: true,
                placeholder: "Beneficiary Name",
            },
            {
                key: 'accCountry',
                label: 'Designated Country',
                placeholder: 'Designated Country',
                required: true,
                type: 'select',
                options: designatedCountryTypes,
                keyAccessor: 'value',
                valueAccessor: 'name',
            },
            {
                key: "accIdentificationNo2",
                label: bankCodeLabel,
                required: true,
                placeholder: bankCodePlaceholder,
            },
            {
                key: "accIdentificationNo",
                label: "Bank Account No",
                required: true,
                placeholder: "Bank Account No",
            },

            {
                key: "bankName",
                label: "Bank Name",
                required: true,
                placeholder: "Bank Name",
            },
            {
                key: "bankAddress",
                label: "Bank/Branch Address",
                required: true,
                placeholder: "Bank/Branch Address",
            },
            // {
            //     key: "amount",
            //     label: "Amount",
            //     required: true,
            //     placeholder: "Amount",
            //     type: 'number',
            // },
        ],
    };
    const handleBeneficiaryInputChange = (key, value) => {
        setBeneficiaryData((prevData) => ({...prevData, [key]: value}));

        if (key === 'accCountry') {
            updateBankCodeLabelAndPlaceholder(value);
        }
    };

    const updateBankCodeLabelAndPlaceholder = (countryCode) => {
        switch (countryCode) {
            case 'CA':
                setBankCodeLabel('Transit Code');
                setBankCodePlaceholder('Enter Transit Code');
                break;
            case 'AU':
                setBankCodeLabel('BSB Code');
                setBankCodePlaceholder('Enter BSB Code');
                break;
            case 'GB':
            case 'SA':
            case 'BH':
            case 'AE':
            case 'EU':
            case 'IE':
            case 'DE':
                setBankCodeLabel('IBAN Code');
                setBankCodePlaceholder('Enter IBAN Code');
                break;
            case 'US':
                setBankCodeLabel('Routing Code');
                setBankCodePlaceholder('Enter Routing Code');
                break;
            default:
                setBankCodeLabel('Bank Code');
                setBankCodePlaceholder('Enter Bank Code');
        }
    };

    const updateBeneficiary = async (e) => {
        e.preventDefault();
        const payload = {
            accountInfoList: [
                {
                    name: senderData.name,
                    accountNo: beneficiaryData.accIdentificationNo,
                    bankName: senderData.bankName,
                    bankCode: beneficiaryData.accIdentificationNo2,
                    bankAddress: senderData.bankAddress,
                    country: beneficiaryData.accCountry,
                    address: 'address',
                    idNumber: senderData.idNumber,
                }
            ],
            accIdentificationNo: beneficiaryData.accIdentificationNo,
            accIdentificationNo2: beneficiaryData.accIdentificationNo2,
            accIdentificationType: beneficiaryData.accIdentificationType,
            accCountry: beneficiaryData.accCountry,
        };

        // console.log("Payload to send:", payload);

        let response = await dispatch(createBeneficiaryFxn(appId, payload));
        if (response && !response.error) {
            notification.success({
                message: 'Beneficiary Information Updated Successfully',
            });
            // setBeneficiaryData({});
        } else {
            notification.error({message: response.message});
        }
    };

    const renderBeneficiaryInputField = (field) => {
        if (field.key === 'accIdentificationNo2') {
            return (
                <Input
                    value={beneficiaryData[field.key] || ''}
                    className={'form-control'}
                    placeholder={bankCodePlaceholder}
                    onChange={(e) => handleBeneficiaryInputChange(field.key, e.target.value)}
                />
            );
        }

        if (field.type === 'select') {
            return (
                <Select
                    value={beneficiaryData[field.key] || field.defaultValue || undefined}
                    onChange={(value) => handleBeneficiaryInputChange(field.key, value)}
                    placeholder={field.placeholder}
                >
                    {field.options.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.name}
                        </Option>
                    ))}
                </Select>
            );
        } else {
            return (
                <Input
                    value={beneficiaryData[field.key] || ''}
                    className={'form-control'}
                    type={field.type === 'date' ? 'date' : field.type}
                    placeholder={field.placeholder}
                    onChange={(e) => handleBeneficiaryInputChange(field.key, e.target.value)}
                />
            );
        }
    };

    const documents = [
        {label: 'Course Invoice', imgSrc: ArtBoard, docName: 'COURSE_INVOICE', side: 'FRONT'},
        {label: 'Passport Front', imgSrc: PassFront, docName: 'PASSPORT', side: 'FRONT'},
        {label: 'Passport Back', imgSrc: PassBack, docName: 'PASSPORT', side: 'BACK'},
        {label: 'Loan Disbursement Letter', imgSrc: ArtBoard, docName: 'LOAN', side: 'FRONT'},
        {label: 'Other Document', imgSrc: OtherDoc, docName: 'VISA', side: 'FRONT'},
        {key: 'passportIssuingCountry', label: 'Passport Issuing Country'},
    ];
    const handleFileUpload = async (file, docName, docSide) => {
        // console.log(file, "fiilleel")
        const fd = new FormData();
        fd.append('file', file);
        fd.append('applicationId', appId);
        fd.append('documentName', docName);
        fd.append('documentSide', docSide);
        fd.append('documentType', 'ID_PROOF');

        let resp = await dispatch(updateStudentDocFxn(fd));
        if (resp) {
            // window.location.reload()
            getDocById()
            apiRequest();
        }
        // console.log(resp, "errees")
    };

    const senderDocuments = [
        {key: 'addressProof', label: 'Address Proof', type: 'select'},
        {
            key: 'addressProofFront',
            label: 'Address Proof Front',
            imgSrc: ArtBoard,
            docName: 'front',
            side: 'FRONT',
            type: 'ADDRESS_PROOF'
        },
        {
            key: 'addressProofBack',
            label: 'Address Proof Back',
            imgSrc: ArtBoard,
            docName: 'back',
            side: 'BACK',
            type: 'ADDRESS_PROOF'
        },
        {key: 'panCard', label: 'Pan Card', imgSrc: Pancard, docName: 'pan', side: 'FRONT', type: 'ADDRESS_PROOF'},
        {
            key: 'otherDoc',
            label: 'Other Document',
            imgSrc: OtherDoc,
            docName: 'other',
            side: 'FRONT',
            type: 'ADDRESS_PROOF'
        },

    ];
    const uploadFileWithFormData = (file, userId, documentName, documentSide, documentType) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('userId', userId);
        formData.append('accountRef', senderData.accountRef);
        formData.append('documentName', documentName);
        formData.append('documentSide', documentSide);
        formData.append('documentType', documentType);

        let resp = dispatch(updateSenderDocFxn(formData));
        // if(resp.success) {
        //     notification.success({message: 'Document uploaded successfully'})
        // } else {
        //     notification.error({message: resp.errors})
        // }
    };

    const handleSenderFileUpload = (file, documentName, documentSide, documentType) => {
        let userId = '53ac1ae8-00c2-4dba-b059-957f7ddb9d6c'
        if (senderData && senderData.accountRef) {
            uploadFileWithFormData(file, userId, documentName, documentSide, documentType);
        } else {
            setSelectedSenderDocuments(prev => [...prev, {file, userId, documentName, documentSide, documentType}]);
        }
    };


    const getSenderDocs = async (accountRef) => {
        let resp = await dispatch(getSenderDocsFxn({accountRef}));
        // console.log(resp, "Sender Document Response");
        setSenderDocumentData(resp)
    };

    const senderUploadedDocument = (docName, docSide) => {

        if (senderDocumentData === []) {
            return [];
        }
        return senderDocumentData && senderDocumentData.find(doc => doc.documentName === docName && doc.status === 'uploaded' && doc.documentSide === docSide);
    };

    const handleInputChange = (key, value) => {
        setStudentData((prevData) => ({...prevData, [key]: value}));
    };

    const handleSenderInputChange = (key, value) => {
        setSenderData((prevData) => ({...prevData, [key]: value}));

        if (key === 'accOccupationType') {
            if (value === 'salaried') {
                setOccupationValOptions(SalariedTypes);
            } else if (value === 'selfEmployedProfessional') {
                setOccupationValOptions(SelfProfessionalTypes);
            } else if (value === 'selfEmployedBusiness') {
                setOccupationValOptions(SelfBusinessTypes);
            } else {
                setOccupationValOptions([]); // Clear options if no type is selected
            }
        }
    };

    const updateStudent = async () => {
        const payload = {
            country: studentData.country,
            name: studentData.name,
            studentName: studentData.studentName,
            courseDetails: studentData.courseDetails,
            studentUniversityId: studentData.studentUniversityId,
            passportNumber: studentData.passportNumber,
            courseEndMonth: studentData.courseEndMonth,
            courseStartMonth: studentData.courseStartMonth,
            paymentDueDate: studentData.paymentDueDate,
            otherUniversityName: studentData.otherUniversityName

        };

        let resp = await dispatch(updateStudentFxn(appId, payload));
        if (resp.success) {
            notification.success({message: resp.message})
        } else {
            notification.error({message: resp.message})
        }
        // console.log(resp, "rsp");
    };

    const updateSender = async (e) => {
        e.preventDefault();
        const {form} = props;

        form.validateFields(async (err, valData) => {
            if (!err) {
                const payload = {
                    accountInfoList: [
                        {
                            ...(senderData.accountRef ? {amount: valData.amount} : 100),
                            purpose: valData.purpose,
                            source: valData.source,
                            name: valData.name,
                            accountNo: valData.accountNo,
                            bankName: valData.bankName,
                            bankCode: valData.bankCode,
                            bankAddress: valData.bankAddress,
                            city: valData.city,
                            state: valData.state,
                            address: 'address',
                            idNumber: valData.idNumber,
                            accountRef: senderData.accountRef,
                        }
                    ],
                    accState: valData.accState,
                    accPlaceOfBirth: valData.accPlaceOfBirth,
                    accPinCode: valData.accPinCode,
                    accNationality: valData.accNationality,
                    accMobile: valData.accMobile,
                    accGender: valData.accGender,
                    accEmailId: valData.accEmailId,
                    accDob: valData.accDob,
                    accForeignCharges: valData.accForeignCharges,
                    accCountryOfBirth: valData.accCountryOfBirth,
                    accAddressProofName: valData.accAddressProofName,
                    accCity: valData.accCity,
                    accAddress: valData.accAddress,
                    accPEP: valData.accPEP,
                    accSalutation: valData.accSalutation,
                    accSourceOfIncome: valData.accSourceOfIncome,
                    accCustomerId: valData.accCustomerId,
                    accAddressProofNo: valData.accAddressProofNo,
                    accAddressProofExpiryDate: valData.accAddressProofExpiryDate,
                    accGrossIncome: valData.accGrossIncome,
                    accOccupationType: valData.accOccupationType,
                    accOccupationVal: valData.accOccupationVal,
                    // idType: selectedAddressProof,
                };

                let response;
                if (senderData.accountRef) {
                    let accountRef = senderData.accountRef
                    response = await dispatch(updateSenderFxn(appId, accountRef, payload));

                } else {
                    response = await dispatch(createSenderFxn(appId, payload));

                }

                if (response && !response.error) {
                    notification.success({
                        message: senderData.accountRef ? 'Sender Information Updated Successfully' : 'Sender Created Successfully',
                    });

                    // props.form.resetFields();
                    // if (senderData.accountRef) {
                    //     selectedSenderDocuments.forEach(doc => {
                    //         uploadFileWithFormData(doc.file, doc.documentName, doc.documentSide, doc.documentType);
                    //     });
                    // }
                } else {
                    notification.error({message: response.message});
                }
            } else {
                notification.warning({
                    message: 'Fill All Required Fields',
                });
            }
        });
    };

    const columns = [
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Current Rate',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Amount',
            dataIndex: 'fromCurrencyAmount',
            key: 'fromCurrencyAmount',
        },
    ];

    const dataSource = [
        {
            key: '1',
            currency: chargeComponent.fromCurrency || 'N/A',
            rate: chargeComponent.fxRate || 'N/A',
            fromCurrencyAmount: chargeComponent.fromCurrencyAmount || 'N/A',
        },
    ];
    const formItemLayout = {
        labelCol: {
            xs: {span: 0},
            sm: {span: 0},
            md: {span: 0}
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 24},
            md: {span: 24}
        }
    }
    const docSource = [
        {
            key: '1',
            name: 'A2 Form',
        },
        {
            key: '2',
            name: 'Signed A2 Form',
        },
        {
            key: '3',
            name: 'Swift Copy',
        },
    ];
    const docColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'View',
            key: 'view',
            render: (_, record) => {
                if (record.name === 'A2 Form') {
                    return (
                        <span>
                            {kycStatus === 'PENDING' ? (
                                <Icon
                                    type="eye-invisible"
                                    style={{fontSize: '18px', marginRight: '10px', cursor: 'not-allowed'}}
                                />
                            ) : (
                                <Icon
                                    type="eye"
                                    style={{fontSize: '18px', marginRight: '10px', cursor: 'pointer'}}
                                    onClick={() => handleView()}
                                />
                            )}
                        </span>
                    );
                }
                if (record.name === 'Swift Copy') {
                    return (
                        <Icon
                            type="eye-invisible"
                            style={{fontSize: '18px', marginRight: '10px', cursor: 'not-allowed'}}
                        />
                    );
                }
                return (
                    <span>
                         <Upload
                             beforeUpload={(file) => {
                                 handleUpload(file);
                                 return false;
                             }}
                         >
                           <Icon
                               type="upload"
                               style={{fontSize: '18px', marginRight: '10px', cursor: 'pointer'}}
                           />
                         </Upload>

                    </span>
                );
            },
        },
        // {
        //     title: 'Download',
        //     key: 'download',
        //     render: () => (
        //         <a href={''}>
        //             <img
        //                 src={'/assets/icons/cloud-computing-download.png'}
        //                 alt="Download"
        //                 style={{ maxWidth: '4%' }}
        //             />
        //         </a>
        //     ),
        // },
    ];


    const handleView = async () => {
        const response = await dispatch(getA2FormFxn({applicationId: appId}));
        // console.log(response)
        if (response.success) {
            notification.success({message: response.errors[0]})
        } else {
            notification.error({message: response.message})
        }
    }
    const handleUpload = async (file) => {
        // console.log(file, "fiilleel")
        const fd = new FormData();
        fd.append('file', file);
        fd.append('applicationId', appId);
        let resp = await dispatch(uploadA2FormFxn(fd))
        // console.log(resp, "respppp")
        // console.log(`Upload clicked`);
    };

    const renderInputField = (field) => {
        if (field.type === 'select') {
            return (
                <Select
                    value={senderData[field.key] || undefined}
                    onChange={(value) => handleSenderInputChange(field.key, value)}
                    placeholder={field.placeholder}
                    disabled={field.disabled || false}
                >
                    {field.key === 'accOccupationVal'
                        ? occupationValOptions.map(option => (
                            <Option key={option.value} value={option.value}>
                                {option.name}
                            </Option>
                        ))
                        : field.options.map(option => (
                            <Option key={option.value} value={option.value}>
                                {option.name}
                            </Option>
                        ))
                    }
                </Select>
            );
        } else if (field.type === 'radio') {
            return (
                <Radio.Group
                    value={senderData[field.key] || undefined}
                    onChange={(e) => handleSenderInputChange(field.key, e.target.value)}
                    buttonStyle="solid"
                >
                    {field.options.map(option => (
                        <Radio key={option.value} value={option.value}>
                            {option.label}
                        </Radio>
                    ))}
                </Radio.Group>
            );
        } else {
            return (
                <Input
                    value={senderData[field.key] || ''}
                    className={'form-control'}
                    type={field.type === 'date' ? 'date' : 'text'}
                    placeholder={field.placeholder}
                    disabled={field.disabled || false}
                    onChange={(e) => handleSenderInputChange(field.key, e.target.value)}
                />
            );
        }
    };


    const findUploadedDocument = (docName, docSide) => {
        // Check if documentsData is null
        if (documentsData === []) {
            return []; // Return null if documentsData is null
        }
        return documentsData && documentsData.find(doc => doc.documentName === docName && doc.status === 'uploaded' && doc.documentSide === docSide);
    };

    const getDownloadDocs = async (e, appId, name, side) => {
        e.preventDefault();
        try {
            let resp = await dispatch(downloadStudentDocFxn({
                applicationId: appId,
                documentName: name,
                documentSide: side
            }));

            if (resp && resp.data) {
                const {data: base64String, contentType} = resp;
                const mimeType = contentType || 'application/octet-stream';

                const imageUrl = `data:${mimeType};base64,${base64String}`;

                setImageUrl(imageUrl);
            } else {
                console.error('No data found in response');
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    const downloadPayment = async () => {
        let resp = await dispatch(paymentInstructionFxn({applicationId: appId}))
        if (resp) {
            window.open(resp.file.path, '_blank');
        } else {
            notification.error({message: "Failed to retrieve payment instruction."});
        }
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <Card>
                        <Collapse
                            activeKey={activeKey}
                            onChange={(key) => setActiveKey(key)}
                        >
                            <Panel header="Student Details" key="1">
                                <div>
                                    <Row span={16}>
                                        <div>
                                            <h6>Document Upload
                                                <span
                                                    style={{fontSize: '10px', color: 'red'}}>(Max File Size 5MB)</span>
                                            </h6>
                                        </div>

                                        {documents.map((doc, index) => {
                                            const uploadedDocument = findUploadedDocument(doc.docName, doc.side);

                                            return doc.imgSrc ? (
                                                <Col span={3} key={index} className={'mt20 custom-exchange-col mr20'}>
                                                    <div
                                                        className='custom-exchange bordered'
                                                        title={<span
                                                            className='custom-exchange-title'>{doc.label}</span>}
                                                        bordered={false}
                                                        style={{textAlign: 'center', marginBottom: '16px'}}
                                                    >
                                                        <div className="custom-exchange-body">
                                                            <p className={'doc-label'}>{doc.label}</p>
                                                            <img src={doc.imgSrc} alt={doc.label}
                                                                 className="custom-exchange-img"/>
                                                            {uploadedDocument ? (
                                                                <>
                                                                    <Upload
                                                                        beforeUpload={(file) => {
                                                                            handleFileUpload(file, doc.docName, doc.side);
                                                                            return false;
                                                                        }}
                                                                    >
                                                                        <Button type='primary'
                                                                                className="custom-exchange-btn">Replace</Button>
                                                                    </Upload>
                                                                    <div className={'mt-1'}>
                                                                        <a href={''}
                                                                           onClick={(e) => getDownloadDocs(e, appId, doc.docName, doc.side)}
                                                                           className="custom-exchange-link">View</a>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <Upload
                                                                    beforeUpload={(file) => {
                                                                        handleFileUpload(file, doc.docName, doc.side);
                                                                        return false;
                                                                    }}
                                                                >
                                                                    <Button type='primary'
                                                                            className="custom-exchange-btn">Upload</Button>
                                                                </Upload>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            ) : (
                                                <Col span={5} key={index} className={'mt20 ml5'}>
                                                    <InputBox title={'Passport Issuing Country'} className={'mt20'}/>
                                                    <Input
                                                        placeholder="Passport Issuing Country"
                                                        className={'form-control'}
                                                        value={'India'}
                                                        label={'Passport Issuing Country'}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </Row>

                                </div>

                                <div className='form-box'>
                                    <div className='card unizportal'>
                                        <Form className={'vertical-form'}>
                                            <Row gutter={16}>
                                                <div>
                                                    <h6>Student Info</h6>
                                                </div>
                                                {inputTypes.fields.map((field, index) => (
                                                    <Col span={8} key={index}>
                                                        <InputBox
                                                            title={field.required ? `${field.label} *` : field.label}>
                                                            <Form.Item>
                                                                {field.type === 'select' ? (
                                                                    <Select
                                                                        value={studentData[field.key] || undefined}
                                                                        onChange={(value) => {
                                                                            handleInputChange(field.key, value);
                                                                            field.onChange(value); // Call onChange method defined in field
                                                                        }}
                                                                        placeholder={field.placeholder}
                                                                    >
                                                                        {field.options.map(option => (
                                                                            <Option key={option[field.keyAccessor]}
                                                                                    value={option[field.keyAccessor]}>
                                                                                {option[field.valueAccessor]} {/* Display the country name */}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                ) : (
                                                                    <Input
                                                                        value={studentData[field.key] || ''}
                                                                        className={'form-control'}
                                                                        type={field.type}
                                                                        placeholder={field.placeholder}
                                                                        onChange={(e) => handleInputChange(field.key, e.target.value)} // Capture input changes
                                                                    />
                                                                )}
                                                            </Form.Item>
                                                        </InputBox>
                                                    </Col>
                                                ))}
                                            </Row>

                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <InputBox title="Payment Due Date">
                                                        <Form.Item>
                                                            <DatePicker
                                                                className="form-control"
                                                                style={{width: '100%'}}
                                                                value={studentData.paymentDueDate ? moment(studentData.paymentDueDate) : null}
                                                                onChange={(date, dateString) => {
                                                                    setStudentData({
                                                                        ...studentData,
                                                                        paymentDueDate: dateString
                                                                    }); // Use dateString or format 'date' if needed
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </InputBox>
                                                </Col>
                                                <Col span={8}>
                                                    <InputBox title="Course Start Date">
                                                        <Form.Item>
                                                            <DatePicker
                                                                className="form-control"
                                                                style={{width: '100%'}}
                                                                value={studentData.courseStartMonth ? moment(studentData.courseStartMonth) : null}
                                                                onChange={(date, dateString) => {
                                                                    setStudentData({
                                                                        ...studentData,
                                                                        courseStartMonth: dateString
                                                                    }); // Use dateString or format 'date' if needed
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </InputBox>
                                                </Col>
                                                <Col span={8}>
                                                    <InputBox title="Course End Date">
                                                        <Form.Item>
                                                            <DatePicker
                                                                className="form-control"
                                                                style={{width: '100%'}}
                                                                value={studentData.courseEndMonth ? moment(studentData.courseEndMonth) : null}
                                                                onChange={(date, dateString) => {
                                                                    setStudentData({
                                                                        ...studentData,
                                                                        courseEndMonth: dateString
                                                                    }); // Use dateString or format 'date' if needed
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </InputBox>
                                                </Col>
                                            </Row>

                                            <Form.Item className={'text-center'}>
                                                <Button
                                                    type='primary'
                                                    htmlType='submit'
                                                    className={'btn'}
                                                    onClick={() => updateStudent()}
                                                >
                                                    Save
                                                </Button>

                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </Panel>

                            <Panel header="Sender Details" key="2">
                                <div>
                                    <div>
                                        <h6>Document Upload</h6>
                                    </div>
                                    <Row span={16}>
                                        {senderDocuments.map((doc, index) => {
                                            const uploadedDocument = senderUploadedDocument(doc.docName, doc.side); // Reuse the findUploadedDocument function
                                            return doc.imgSrc ? (
                                                <Col span={3} key={index} className={'mt20 custom-exchange-col mr20'}>
                                                    <div
                                                        className='custom-exchange bordered'
                                                        title={<span
                                                            className='custom-exchange-title'>{doc.label}</span>}
                                                        bordered={false}
                                                        style={{textAlign: 'center', marginBottom: '16px'}}
                                                    >
                                                        <div className="custom-exchange-body">
                                                            <p className={'doc-label'}>{doc.label}</p>
                                                            <img src={doc.imgSrc} alt={doc.label}
                                                                 className="custom-exchange-img"/>
                                                            {uploadedDocument ? (
                                                                <>
                                                                    <Button
                                                                        type={'primary'}
                                                                        className="custom-exchange-btn"
                                                                        onClick={() => handleSenderFileUpload(null, doc.docName, doc.side, doc.type)}
                                                                    >
                                                                        Replace
                                                                    </Button>
                                                                    <div className={'mt-1'}>
                                                                        <a
                                                                            href={''}
                                                                            // onClick={() => getSenderDocs(senderData.accountRef)}
                                                                            className="custom-exchange-link"
                                                                        >
                                                                            View
                                                                        </a>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <Upload
                                                                    beforeUpload={(file) => {
                                                                        handleSenderFileUpload(file, doc.docName, doc.side, doc.type);
                                                                        return false;
                                                                    }}
                                                                >
                                                                    <Button type='primary'
                                                                            className="custom-exchange-btn">Upload</Button>
                                                                </Upload>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Col>
                                            ) : (
                                                <Col span={5} key={index} className={'mt20 ml5 mr20'}>
                                                    <InputBox title={'Address Proof'} className={'mt20'}/>
                                                    <Select
                                                        placeholder="Select Address Proof"
                                                        onChange={handleAddressProofChange} // Handles selection change
                                                        value={selectedAddressProof}
                                                    >
                                                        {addressProofTypes.map(option => (
                                                            <Option key={option.value} value={option.value}>
                                                                {option.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                            );
                                        })}
                                    </Row>


                                    <div className='form-box'>
                                        <div className='card unizportal'>
                                            <Form className={'vertical-form'} onSubmit={updateSender}>
                                                <Row gutter={16}>
                                                    {senderInput.fields.map((field, index) => (
                                                        <Col span={12} key={index}>
                                                            <Form.Item label={field.label}>
                                                                {getFieldDecorator(field.key, {
                                                                    rules: [{
                                                                        required: field.required,
                                                                        message: `${field.label} is required`
                                                                    }],
                                                                })(
                                                                    renderInputField(field)
                                                                )}
                                                            </Form.Item>
                                                        </Col>
                                                    ))}
                                                </Row>

                                                <Form.Item className={'text-center'}>
                                                    <Button
                                                        type='primary'
                                                        htmlType='submit'
                                                        className={'btn'}

                                                    >
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="Beneficiary" key="3">
                                <div className='form-box'>
                                    <div className='card unizportal'>
                                        <Form className={'vertical-form'} onSubmit={updateBeneficiary}>
                                            <Row gutter={16}>
                                                {beneficiaryInput.fields.map((field, index) => (
                                                    <Col span={12} key={index}>
                                                        <InputBox
                                                            title={field.required ? `${field.label} *` : field.label}>
                                                            <Form.Item>
                                                                {renderBeneficiaryInputField(field)}
                                                            </Form.Item>
                                                        </InputBox>
                                                    </Col>
                                                ))}
                                            </Row>
                                            <Form.Item>
                                                <Row>
                                                    <Col md={12}>
                                                        <Button htmlType={'submit'} className={'roundBtn'}>
                                                            Save
                                                        </Button>
                                                        <Button className={'roundBtn ml5'}>
                                                            Skip
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </Panel>

                            <Panel key={'4'} header={'Payment Details'}>
                                <div className="form-box payment-details-container">
                                    <div className="payment-details-row row">
                                        <div className="payment-details-col col-md-9">
                                            <Card bordered={false} className="payment-details-card">
                                                <h6>Payment Details</h6>
                                                <Table
                                                    dataSource={dataSource}
                                                    columns={columns}
                                                    pagination={false}
                                                    bordered
                                                />
                                            </Card>
                                            <div className={'mt20 ml20'}>
                                                <h6>Amount in Words: <strong>{chargeComponent.inrAmountInWords}</strong>
                                                </h6>
                                                <h6>Amount: <strong>{chargeComponent.fcyAmountInWords}</strong></h6>
                                            </div>
                                        </div>

                                        {/* Order Summary */}
                                        <div className="order-summary-col col-md-3">
                                            <Card bordered={false} className="order-summary-card">
                                                <h6>Your Order Summary</h6>
                                                <div className="order-summary-content">
                                                    <Row className="order-summary-row">
                                                        <Col span={12}>
                                                            <p>Total Amount:
                                                                <Tooltip title="Click to view details">
                                                                    <Icon
                                                                        type="info-circle"
                                                                        style={{marginLeft: 8, cursor: 'pointer'}}
                                                                        onClick={showChargeModal}
                                                                    />
                                                                </Tooltip>
                                                            </p>
                                                        </Col>
                                                        <Col span={12} className="text-right">
                                                            <p>{chargeComponent.base}</p>
                                                        </Col>
                                                    </Row>

                                                    <Row className="order-summary-row">
                                                        <Col span={12}>
                                                            <strong>Total Amount Payable:</strong>
                                                        </Col>
                                                        <Col span={12} className="text-right">
                                                            <strong>{chargeComponent.total}</strong>
                                                        </Col>
                                                    </Row>


                                                </div>
                                            </Card>

                                            <div className={'mt5 text-center'}>
                                                <a className={'mt5'}
                                                   onClick={showModal}
                                                >Click here to update kyc status</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isChargeModal ? <Modal
                                    visible={isChargeModal}
                                    onCancel={handleCancel}
                                    footer={null}
                                    closable={true}
                                    width={300}
                                    style={{top: '273px', width: '260px', marginLeft: 'calc(50% - -510px)'}}>
                                    <Table
                                        dataSource={[
                                            {
                                                key: '1',
                                                description: 'TCS',
                                                amount: `₹${stringToFixed(chargeComponent.tcs)}`,
                                            },
                                            {
                                                key: '2',
                                                description: 'Nostro',
                                                amount: `₹${stringToFixed(chargeComponent.nostro)}`,
                                            },
                                            {
                                                key: '3',
                                                description: 'GST',
                                                amount: `₹${stringToFixed(chargeComponent.gst)}`,
                                            },
                                            {
                                                key: '4',
                                                description: 'Processing Fee',
                                                amount: `₹${stringToFixed(chargeComponent.bank)}`,
                                            },
                                            {
                                                key: '5',
                                                description: 'Discount',
                                                amount: <span
                                                    style={{color: 'green'}}>{`₹${chargeComponent.discount}`}</span>,
                                            },
                                        ]}
                                        columns={[
                                            {
                                                title: 'Description',
                                                dataIndex: 'description',
                                                key: 'description',
                                            },
                                            {
                                                title: 'Amount',
                                                dataIndex: 'amount',
                                                key: 'amount',
                                            },
                                        ]}
                                        pagination={false}
                                        bordered
                                    />
                                </Modal> : null}

                            </Panel>

                        </Collapse>
                    </Card>
                </div>
            </div>
            <Card className="status-information-card mt20">
                <h6>Status Information</h6>
                <Row gutter={16}>
                    <Col span={6}>
                        <div className="status-item">
                            <strong>KYC Status:</strong>
                            <label
                                className={kycStatus === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                {kycStatus}
                            </label>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="status-item">
                            <strong>Transaction Status:</strong>
                            <label
                                className={txnStatus === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                {txnStatus}
                            </label>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="status-item">
                            <strong>A2 Form Status:</strong>
                            <label
                                className={a2FormStatus === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                {a2FormStatus}
                            </label>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="status-item">
                            <strong>KYC Consultant Status:</strong>
                            <label
                                className={kycStatusConsultant === 'DONE' ? 'label label-success-green' : 'label label-success'}>
                                {kycStatusConsultant}
                            </label>
                        </div>
                    </Col>
                </Row>
            </Card>

            <div className={'row mt20 text-center'}>
                <div className={'col-md-6'}>
                    <Button type={'primary'} style={{width: '97%'}}
                            onClick={downloadPayment}
                    >Download Payment Instruction</Button>
                </div>
                {/*<div className={'col-md-6'}>
                    <Button type={'primary'} style={{width: '97%'}}>Email Payment Instruction</Button>
                </div>*/}
            </div>
            <div className={'mt20'}>
                <Card bordered={true}>
                    <Table dataSource={docSource} columns={docColumns} pagination={false}/>
                </Card>
            </div>
            {
                isModalVisible ?
                    <UpdateKycStatus
                        visible={isModalVisible}
                        onClose={closeModal}
                        appId={appId}
                    /> : null
            }

            {imageUrl && (
                <Modal
                    title="Document Preview"
                    visible={true}
                    footer={null}
                    width={'70%'}
                    onCancel={() => setImageUrl(null)}>
                    <div className={'alignCenter'}>
                        <img
                            src={imageUrl}
                            alt="Document Preview"
                            style={{width: 'auto', height: 600}}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
};


export default Form.create()(EditTxnStudent)
